/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC, useEffect } from 'react';
import { TabPanel, TableContent } from '@orientaction/components';
import { Switch } from '@orientaction/commons';
import { useAdminUser } from '@orientaction/hooks';
import { formatData, getFromLS } from '@orientaction/utils';
import { useHistory } from 'react-router-dom';
import Result from './Result';
import { useDispatch, useSelector } from 'react-redux';
import { ApiActions } from '@orientaction/api-actions';

interface IListTabsAdmin {
  typeTestChecked?: any;
}

const ListTabsAdmin: FC<IListTabsAdmin> = ({ typeTestChecked }) => {
  const {
    actived,
    tabs,
    company,
    countTabs,
    textFilter,
    loadingList,
    listAdminUser,
    listAdminPanel,
    currentPagination,
    rattachement,
    setActived,
    setCurrentPagination,
    onDelete,
    oneDisable,
    setCurrent,
    setOpenDrawer,
    getAdminUserList,
    setListUphierchy,
    setRattachement,
    getAdminUserListZoneInfluance,
  } = useAdminUser();

  const dispatch = useDispatch();
  const refetch = useSelector((state: any) => state?.api?.refetch);

  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');

  const history = useHistory();

  const onEdit = (data: any) => {
    setListUphierchy([
      {
        ...data.upper_hierarchy?.data.attributes,
        id: data.upper_hierarchy?.data.id,
      } || null,
    ]);

    setCurrent({
      ...data,
      company: company as number,
      company_user_role: data.company_user_role?.data?.id || '',
      upper_hierarchy: data.upper_hierarchy?.data?.id || '',
      name_upper_hierarchy: `${data.upper_hierarchy?.data?.attributes.firstname} ${data.upper_hierarchy?.data?.attributes.lastname}`,
      isView: !!data.isView,
      isUpdate: true,
    });

    setOpenDrawer(true);
  };

  const updateUserRattachedFilter = (e: any) => {
    // update user admin rattached flag in store
    setRattachement(e.target.checked);
    dispatch(ApiActions.setRefetch(true));
  };

  const updateUserActiveFilter = (e: any) => {
    // update user admin active flag in store
    setActived(e.target.checked);
    dispatch(ApiActions.setRefetch(true));
  };

  const handleChangePage = (event: any, newPage: number) => {
    setCurrentPagination(newPage);

    if (userConnected?.role?.type !== 'admin') {
      getAdminUserListZoneInfluance(
        userConnected.id,
        tabs,
        newPage,
        textFilter,
        actived,
        rattachement
      );
    }

    if (userConnected?.role?.type === 'admin') {
      getAdminUserList(
        company,
        tabs,
        newPage,
        textFilter,
        actived,
        rattachement
      );
    }
  };

  const btnClick = (data: any, key: any) => {
    if (key === 'subordinates') {
      onEdit({
        ...data,
        isView: false,
        isUpdate: false,
        isHierarchy: true,
      });
    }

    if (key === 'viewTest') {
      history.push(`/admin/resultat-beneficiaire/${data.id}`);
    }

    if (key === 'ressource') {
      history.push(`/admin/ressources-beneficiaire/${data.id}`);
    }

    if (key === 'firstname' || key === 'lastname') {
      history.push(`/main/user/${data.id}`);
    }
  };

  return (
    <>
      <div
        style={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >

        {tabs !== 6 && (
          <Switch
            checked={!!actived}
            onChange={updateUserActiveFilter}
            label="Utilisateurs actifs"
            cy="user-actif"
          />
        )}

        {tabs === 5 && company === 1 && userConnected?.role?.type === 'admin' && (
          <Switch
            checked={rattachement}
            onChange={updateUserRattachedFilter}
            label="Utilisateurs rattachés"
            cy="user-rattache"
          />
        )}

        <div
          style={{
            marginTop: '10px',
            marginBottom: '20px'
          }}
        >

          {/*
           *
           * listAdminPanel
           *
           * in Component/AdminTab
           *
           * seter
           *
           */}

          {listAdminPanel.map((item: any, index: number) => {
            if (!item.viewNbr) {
              return (
                <TabPanel value={tabs} index={6} key={`${item.userTypeConnected}`}>
                  <Result typeTestChecked={typeTestChecked} company={company} />
                </TabPanel>
              );
            }

            return (
              <>
                <TabPanel
                  value={tabs}
                  index={item.userTypeConnected}
                  key={`${item.userTypeConnected}`}
                >
                  <TableContent
                    loading={loadingList}
                    bgColor="#23296F"
                    array={formatData(listAdminUser, item.userTypeConnected, rattachement)}
                    coulom={item.colomTable}
                    oneEdit={onEdit}
                    oneDisable={oneDisable}
                    onDelete={onDelete}
                    btnClick={btnClick}
                    pagination={{
                      count: countTabs[item.userTypeConnected] || 0,
                      onChangePage: handleChangePage as any,
                      page: +currentPagination as number,
                      rowsPerPage: 10,
                    }}
                  />
                </TabPanel>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ListTabsAdmin;
