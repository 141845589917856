/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line simple-import-sort/imports
import { ButtonSomaire, FooterEbook, PageCouverture } from '@orientaction/components';
import Page00 from './PagesBook2/00';
import Page01 from './PagesBook2/01';
import Page02 from './PagesBook2/02';
import Page03 from './PagesBook2/03';
import Page04 from './PagesBook2/04';
import Page05 from './PagesBook2/05';
import Page06 from './PagesBook2/06';
import Page07 from './PagesBook2/07';
import Page08 from './PagesBook2/08';
import Page09 from './PagesBook2/09';
import Page10 from './PagesBook2/10';
import Page11 from './PagesBook2/11';
import Page12 from './PagesBook2/12';
import Page13 from './PagesBook2/13';
import Page14 from './PagesBook2/14';
import Page15 from './PagesBook2/15';
import Page16 from './PagesBook2/16';
import Page17 from './PagesBook2/17';
import Page18 from './PagesBook2/18';
import Page19 from './PagesBook2/19';
import Page20 from './PagesBook2/20';
import Page21 from './PagesBook2/21';
import Page22 from './PagesBook2/22';
import Page23 from './PagesBook2/23';
import Page24 from './PagesBook2/24';
import Page25 from './PagesBook2/25';
import Page26 from './PagesBook2/26';
import Page27 from './PagesBook2/27';
import Page28 from './PagesBook2/28';
import Page29 from './PagesBook2/29';
import Page30 from './PagesBook2/30';
import Page31 from './PagesBook2/31';
import Page32 from './PagesBook2/32';
import Page33 from './PagesBook2/33';
import Page34 from './PagesBook2/34';
import Page35 from './PagesBook2/35';
import Page36 from './PagesBook2/36';
import Page37 from './PagesBook2/37';
import Page38 from './PagesBook2/38';
import Page39 from './PagesBook2/39';
import Page40 from './PagesBook2/40';
// New pages addeded
import Page23a1 from './PagesBook2/23a1';
import Page23a2 from './PagesBook2/23a2';
import { useTheme } from '@material-ui/core/styles';
import useStyles from '../style';
import { useBook2 } from '@orientaction/hooks_book';
import { useEffect, useState } from 'react';
import { useAlert, useRedirectToRootPage } from '@orientaction/hooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { LayoutResultat } from '@orientaction/layouts';
import { isAdminVerify } from '@orientaction/utils';

const Book2 = (props: any) => {
  const theme = useTheme();

  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  const [users_permissions_user, setUsers_permissions_user] = useState(0);

  const [readOnly, setReadonly] = useState(true);

  const { id }: any = useParams();

  const classe = useStyles();

  const { width } = props;

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const { initValue, getMyValueBook, setId } = useBook2();
  const { goToRootPage } = useRedirectToRootPage();

  const getValue = async (_id: number) => {
    setLoading(true);

    try {
      const response = await getMyValueBook(id);

      initValue(response.data.value);
      setReadonly(response.data.readOnly);
      setUsers_permissions_user(response.data.value.users_permissions_user.id);
    } catch (err: any) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getValue(+id);
    setId(+id);
  }, [id]);

  return (
    <LayoutResultat
      isWhite={true}
      showLogo={false}
      toBack={
        isAdminVerify()
          ? `/admin/ressources-beneficiaire/${users_permissions_user}`
          : '/mes-ressources'
      }>
      {loading ? (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}>
          <p />
          Chargement en cours...
        </div>
      ) : (
        <div>
          {/* <PageCouverture src="couverture2.jpg" /> */}
          <Page00 />
          <Page01 />
          <Page02 />
          <Page03 />
          <Page04 readOnly={readOnly} />
          <Page05 />
          <Page06 />
          <Page07 />
          <Page08 />
          <Page09 />
          <Page10 />
          <Page11 />
          <Page12 />
          <Page13 />
          <Page14 />
          <Page15 />
          <Page16 readOnly={readOnly} />
          <Page17 />
          <Page18 readOnly={readOnly} />
          <Page19 readOnly={readOnly} />
          <Page20 readOnly={readOnly} />
          <Page21 readOnly={readOnly} />
          <Page22 readOnly={readOnly} />
          <Page23 />
          <Page23a1 readOnly={readOnly} />
          <Page23a2 readOnly={readOnly} />
          <Page24 readOnly={readOnly} />
          <Page25 readOnly={readOnly} />
          <Page26 />
          <Page27 />
          <Page28 />
          <Page29 readOnly={readOnly} />
          <Page30 readOnly={readOnly} />
          <Page31 />
          <Page32 />
          <Page33 readOnly={readOnly} />
          {/* <Page34 readOnly={readOnly} /> */}
          <Page35 readOnly={readOnly} />
          <Page36 readOnly={readOnly} />
          <Page37 />
          <Page38 />
          <Page39 />
          <Page40 readOnly={readOnly} />
          <div className={classe.marginFooter}>
            <FooterEbook
              ebookName="l’Étape 2"
              toBack={
                isAdminVerify()
                  ? `/admin/ressources-beneficiaire/${users_permissions_user}`
                  : '/mes-ressources'
              }
            />
          </div>
          <ButtonSomaire href="page-6" />
        </div>
      )}
    </LayoutResultat>
  );
};

export default Book2;
