import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/HighlightOffOutlined';
import { AdminTestContext } from '@orientaction/Context';
import { FC, useContext } from 'react';
import VariableForm from '../Create/VariableForm';
import style from './style';

const VariableDrawer: FC = () => {
  const { test, updateTest } = useContext<any>(AdminTestContext);
  const classes = style();

  const handleClose = () => {
    updateTest({ variableDrawerOpen: false, variableToEdit: null });
  };

  return (
    <Drawer
      anchor="right"
      open={test.variableDrawerOpen}
      classes={{ root: classes.rootStyle, paper: classes.drawerContent }}>
      <div className={classes.headerContainer}>
        <IconButton className={classes.closeBtn} onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <div className={classes.titleContainer}>
          <Typography variant="h3" className={classes.title}>
            {`${test.variableToEdit ? 'Modifier' : 'Ajouter'} une variable`}
          </Typography>
        </div>
      </div>
      <div className={classes.contentChild}>
        <VariableForm />
      </div>
    </Drawer>
  );
};

export default VariableDrawer;
