/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import DateRangeIcon from '@material-ui/icons/DateRange';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import clsx from 'clsx';
import dateFormat from 'dateformat';
import { FC, useState, useCallback } from 'react';
import { useStyles, AccordionDetails } from './style';
import { useMediaQuery, Theme, Button, ClickAwayListener } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { publicSvgPath } from '@orientaction/utils';

type TimelineProps = {
  testResults: any[];
  idTest: number;
  toRedirect: string;
  //   onChangeDate: (value: number) => void;
};

const HistoryMajorTest: FC<TimelineProps> = ({ testResults, idTest, toRedirect }) => {
  const classes = useStyles();

  const [openHistory, setOpenHistory] = useState<boolean>(false);

  const history = useHistory();

  const handleChange = (id: number) => (e: any) => {
    if (id === idTest) {
      setOpenHistory(false);
      return;
    }
    history.push(`${toRedirect}/${id}`);
  };

  const handleOpenhHistory = () => {
    setOpenHistory(!openHistory);
  };

  const handleClickAway = () => {
    setOpenHistory(false);
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [height, setHeight] = useState(0);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    // <div>
    //   <MuiAccordion
    //     expanded={openHistory}
    //     onChange={handleOpenhHistory}
    //     className={clsx({
    //       [classes.rootAccordion]: true,
    //       [classes.accordionMobileClose]: !openHistory && isMobil,
    //     })}>
    //     <MuiAccordionSummary
    //       aria-controls="panel1d-content"
    //       id="panel1d-header"
    //       className={classes.timelineTitle}>
    //       {((isMobil && openHistory && !isDesktop) || isDesktop) && (
    //         <div className={classes.flexText}>
    //           <p>Historique</p>
    //         </div>
    //       )}
    //       <img
    //         src={`${publicSvgPath}/bouton_historique_compact.svg`}
    //         alt=""
    //         className={classes.imgSVG}
    //       />
    //     </MuiAccordionSummary>

    //     <AccordionDetails>
    //       <ul className={classes.ulHistory}>
    //         {testResults
    //           .sort(
    //             (a: any, b: any) =>
    //               new Date(b?.createdAt as any).valueOf() - new Date(a?.createdAt as any).valueOf()
    //           )
    //           .map((test: any, index: number) => {
    //             return (
    //               <li
    //                 className={clsx({
    //                   [classes.labelPeriod]: true,
    //                   [classes.isHighlighted]: test.id === idTest,
    //                 })}>
    //                 <div onClick={handleChange(test.id)}>
    //                   {test?.createdAt
    //                     ? dateFormat(new Date(test?.createdAt), 'dd/mm/yyyy, HH:MM')
    //                     : '-'}
    //                 </div>
    //               </li>
    //             );
    //           })}
    //       </ul>
    //     </AccordionDetails>
    //   </MuiAccordion>
    // </div>
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {isTab && openHistory && (
          <div
            className={clsx(classes.divUlHistory2, classes.divUlHistory2Mobile)}
            ref={measuredRef}
            style={{ top: -height + 1 }}
          >
            <ul className={clsx(classes.ulHistory2, isTab ? classes.marginBlockEnd : '')}>
              {testResults
                .sort(
                  (a: any, b: any) =>
                    new Date(b?.createdAt as any).valueOf() -
                    new Date(a?.createdAt as any).valueOf(),
                )
                .map((test: any, index: number) => {
                  return (
                    <li
                      className={clsx({
                        [classes.labelPeriod]: true,
                        [classes.isHighlighted]: test.id === idTest,
                      })}
                    >
                      <div onClick={handleChange(test.id)}>
                        {test?.createdAt
                          ? dateFormat(new Date(test?.createdAt), 'dd/mm/yyyy, HH:MM')
                          : '-'}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
        <Button
          endIcon={
            <img src={`${publicSvgPath}/bouton_historique_compact.svg`} alt="couverture intro" />
          }
          className={clsx(
            classes.btnCard,
            openHistory ? (isTab ? classes.bottomTop : classes.bottomRadius) : '',
            isTab ? classes.marginTopNone : '',
          )}
          onClick={handleOpenhHistory}
          style={{ background: 'white', color: 'black' }}
        >
          <span
            className={clsx(
              classes.labelSpan,
              openHistory ? classes.displayFlex : classes.displayNone,
            )}
          >
            Historique
          </span>
        </Button>
        {!isTab && openHistory && (
          <div className={classes.divUlHistory2}>
            <div className={classes.traitGrey} />
            <ul className={classes.ulHistory2}>
              {testResults
                .sort(
                  (a: any, b: any) =>
                    new Date(b?.createdAt as any).valueOf() -
                    new Date(a?.createdAt as any).valueOf(),
                )
                .map((test: any, index: number) => {
                  return (
                    <li
                      className={clsx({
                        [classes.labelPeriod]: true,
                        [classes.isHighlighted]: test.id === idTest,
                      })}
                    >
                      <div onClick={handleChange(test.id)}>
                        {test?.createdAt
                          ? dateFormat(new Date(test?.createdAt), 'dd/mm/yyyy, HH:MM')
                          : '-'}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default HistoryMajorTest;
