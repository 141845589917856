/* eslint-disable no-use-before-define */
import clsx from 'clsx';
import {debounce} from 'lodash';
import { FC } from 'react';
import { useStyles } from './style';

let typeTimeout: any;

interface ITextArea {
  onChange: (e: any) => void;
  name: string;
  value: any;
  placeholder?: string;
  notBorder?: boolean;
  largeTextField?: boolean;
  litleTextField?: boolean;
  veryLitleTextField?: boolean;
  readOnly?: boolean;
}

const TextArea: FC<ITextArea> = ({
  onChange,
  name,
  value,
  placeholder,
  notBorder,
  largeTextField,
  litleTextField,
  veryLitleTextField,
  readOnly,
}) => {
  const classes = useStyles();
  const updateValue = debounce(val => {
    onChange({
      target: {
        name: name as any,
        value: val,
      } as any,
    });
  }, 1000);

  const onKeyDown = (event: any) => {
    console.log('onKeyDown', event.target.value)
    if (readOnly) return;
    updateValue(event.target.value)
  };

  const handleChange = (e: any) => {
    console.log('handleChange', e.target.value)
    if (readOnly) return;
    updateValue(e.target.value)
  };

  const handleChangeGlobalState = (e: any) => {
    if (readOnly) return;
    onChange({
      target: {
        name: name as any,
        value: e.target.value,
      } as any,
    });
  };

  return (
    <textarea
      name={name || ''}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      onBlur={handleChangeGlobalState}
      defaultValue={value}
      placeholder={placeholder || 'Aa'}
      readOnly={readOnly}
      className={clsx({
        [classes.textArea]: true,
        [classes.textAreaNotBorder]: notBorder,
        [classes.largeTextField]: largeTextField,
        [classes.litleTextField]: litleTextField,
        [classes.veryLitleTextField]: veryLitleTextField,
      })}
    />
  );
};

export default TextArea;
