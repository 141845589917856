/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
import { Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { ApiActions } from '@orientaction/api-actions';
import { publicIconPath } from '@orientaction/utils';
import { kebabCase } from 'lodash';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import round from 'lodash/round';
import sumBy from 'lodash/sumBy';
import { FC, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Details from './Details';
import Diagram from './Diagram';
import ResultImage from './ResultImage';
import ShareIcons from './ShareIcons';
import style from './style';

interface IProps {
  userResponse: any;
  variables: any;
}

const getColorByIndex = (index: number) => {
  if (index === 1) {
    return '#FDCE29';
  }
  if (index === 2) {
    return '#F7A82E';
  }
  if (index === 3) {
    return '#ED6637';
  }
  if (index === 4) {
    return '#C93646';
  }
  if (index === 5) {
    return '#942A59';
  }
  if (index === 6) {
    return '#662169';
  }
};

const HorizontalDiagramContainer: FC<IProps> = ({ userResponse, variables }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const classes = style();
  console.log('allvariables', variables);
  /*  const variableGroup = groupBy(userResponse?.attributes.responses.data, (response: any) => {
    return response.attributes.variable.data.id;
  }); */

  /*  const dataMapValue = map(variableGroup, (response: any) => {
    const sumVariable = sumBy(response, (variable: any) => variable.attributes.variableValue);
    return {
      value: sumVariable,
      label: response[0]?.attributes?.variable?.data?.attributes?.name,
      title: response[0]?.attributes?.variable?.data?.attributes?.title,
      image: response[0]?.attributes?.variable?.data?.attributes?.image,
      description: response[0]?.attributes?.variable?.data?.attributes?.description,
      variableId: response[0]?.attributes?.variable?.data?.id,
    };
  }); */

  /* const totalValue = sumBy(dataMapValue, (value: any) => value.value) || 0;
  const userVariablesIds = (dataMapValue || []).map((d: any) => d.variableId);
  const allVariablesIds = variables.map((v: any) => v.id);
  const missingVariablesIds = allVariablesIds.filter((vId: any) => !userVariablesIds.includes(vId));
  const missingVariables = variables.filter((v: any) => missingVariablesIds.includes(v.id));
  const toConcat = missingVariables.map((mV: any) => {
    return {
      value: 0,
      label: mV?.attributes?.name,
      title: mV?.attributes?.title,
      description: mV?.attributes?.description,
      image: '',
      color: '#fff',
    };
  });
  const dataMapValuePourcentage = map(
    dataMapValue.sort((a: any, b: any) => b.value - a.value),
    (value: any, index: number) => {
      const valuePercent = (value.value * 100) / totalValue;
      const valueLabel = value.label;
      return {
        value: round(valuePercent),
        label: valueLabel,
        title: value?.title,
        description: value?.description,
        image: value?.image,
        color: getColorByIndex(index + 1),
      };
    },
  ).concat(toConcat); */

  let allVariables: any[] = [];
  userResponse?.attributes?.responses?.data.forEach((response: any) => {
    response?.attributes.variable.data.forEach((variable: any) => {
      allVariables.push({
        ...variable,
        variableValue: response.attributes.variableValue,
      });
    });
  });

  const variableGroup = groupBy(allVariables, (variable: any) => variable.id);

  const dataMapValue = map(variableGroup, (variables: any[]) => {
    const sumVariable = sumBy(variables, (variable: any) => variable.variableValue);
    const firstVariable = variables[0];

    return {
      value: sumVariable,
      label: firstVariable.attributes.name,
      title: firstVariable.attributes.title,
      image: firstVariable.attributes.image,
      description: firstVariable.attributes.description,
      variableId: firstVariable.id,
    };
  });

  const totalValue = sumBy(dataMapValue, (item: any) => item.value) || 0;

  const userVariablesIds = dataMapValue.map((d: any) => d.variableId);
  const allVariablesIds = variables.map((v: any) => v.id);
  const missingVariablesIds = allVariablesIds.filter((vId: any) => !userVariablesIds.includes(vId));
  const missingVariables = variables.filter((v: any) => missingVariablesIds.includes(v.id));

  const toConcat = missingVariables.map((mV: any) => {
    return {
      value: 0,
      label: mV.attributes.name,
      title: mV.attributes.title,
      description: mV.attributes.description,
      image: '',
      color: '#fff',
    };
  });

  const dataMapValuePourcentage = dataMapValue
    .sort((a: any, b: any) => b.value - a.value)
    .map((item: any, index: number) => {
      const valuePercent = (item.value * 100) / totalValue;
      return {
        ...item,
        value: round(valuePercent, 2),
        color: getColorByIndex(index + 1),
      };
    })
    .concat(toConcat);

  const maximumResponse = dataMapValuePourcentage[0];

  const goToTestPage = () => {
    dispatch(ApiActions.setTabLayout(1 as any));
    history.push('/user/tests');
  };

  const copyTestLink = () => {
    navigator.clipboard.writeText(testUrl);
    setLinkCopied(true);
  };

  const test = userResponse?.attributes?.test?.data;
  const testUrl = `${process.env.REACT_APP_SHARE_ENDPOINT}/api/test/${test.id}/${kebabCase(
    test.attributes.title,
  )}`;

  return (
    <>
      <div className={classes.resultTextParagraph}>{ReactHtmlParser(maximumResponse.title)}</div>
      <Paper className={classes.resultPaperContainer} variant="outlined">
        <Grid alignItems="center" className={classes.diagramContainer} container={true}>
          <Grid className={classes.diagramContent} item={true} sm={12} md={6} lg={6} xl={6}>
            {dataMapValuePourcentage && <Diagram data={dataMapValuePourcentage} />}
          </Grid>
          <Grid item={true} sm={12} md={6} lg={6} xl={6}>
            <ResultImage image={maximumResponse.image} />
          </Grid>
        </Grid>
        <Grid className={classes.detailsContainer} container={true}>
          <Grid item={true} md={12}>
            <Details description={maximumResponse?.description} />
          </Grid>
        </Grid>
        <Grid alignItems="center" justifyContent="center" container={true}>
          <Grid item={true}>
            <Typography className={classes.shareLabelText}>Partager ce test</Typography>
          </Grid>
          <Grid item={true}>
            <div className={classes.shareIconsContainer}>
              <div className={classes.socialMediaIconsContainer}>
                <ShareIcons
                  title={test?.attributes?.title}
                  testUrl={testUrl}
                  description={test?.attributes?.description}
                />
              </div>
              <Button
                endIcon={
                  linkCopied ? undefined : (
                    <img src={`${publicIconPath}/ContentCopy.svg`} alt="Copy icon" />
                  )
                }
                variant="outlined"
                className={classes.studyRestitutionShareButton}
                onClick={copyTestLink}
              >
                {linkCopied ? 'Lien copié !' : 'Copier le lien'}
              </Button>
            </div>
          </Grid>
          <Grid item={true} sm={12}>
            <Typography
              className={classes.shareLabelText}
              style={{ textAlign: 'center', fontSize: '12px', marginTop: 5 }}>
              Les utilisateurs ne verront pas vos résultats, mais pourront passer le test.
            </Typography>
          </Grid>
        </Grid>

        <Button variant="outlined" className={classes.button} onClick={goToTestPage}>
          Faire un nouveau test
        </Button>
      </Paper>
    </>
  );
};

export default HorizontalDiagramContainer;
