import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    wordBreak: 'break-word',
  },
  dot: {
    color: '#E63C3C',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontStyle: 'italic',
    fontWeight: 600,
    lineHeight: 'normal',
  },
  text: {
    color: '#000A8C',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: 'normal',
    '& span': {
      fontFamily: 'Poppins',
    },
  },
  autor: {
    color: '#000A8',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 15,
    },
  },
}));
