/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { CircularProgress, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ContentCenter, MarginContainer } from '@orientaction/commons';
import { CompassUserContext } from '@orientaction/Context';
import { publicImgMajorTest } from '@orientaction/utils';
import { useContext } from 'react';
import ActiveCard from './ActiveCard';
import CardMajorTest from './CardMajorTest';
import { useStyles } from './style';
import { getMajorTestEngUrlByTestId } from './utils';

const noMajorTestText = "Vous n'avez pas accès aux tests majeurs";

const TestMajor = ({ majorTests, loading, searchKey, language = 'FR' }: any) => {
  const { userInfos } = useContext<any>(CompassUserContext);
  const classes = useStyles();

  const userInCardOrNonRattached = !userInfos?.rattachement || userInfos?.service?.id === 5;

  console.log('majorTests', majorTests);
  return (
    <MarginContainer big={true}>
      <Typography variant="h2" className={classes.colorWhite}>
        TESTS MAJEURS
      </Typography>

      {loading ? (
        <>
          <ContentCenter>
            <CircularProgress color="inherit" className={classes.colorWhite} />
          </ContentCenter>
        </>
      ) : (
        <>
          {/* <Grid container={true} spacing={1}>
            <Grid item={true} xl={4} lg={4} md={4} sm={6} xs={12}>
              <OaWithImageCard imageUri={meaningValueGreyImagePath} />
            </Grid>
          </Grid> */}
          {majorTests?.length ? (
            <MarginContainer>
              <Grid container={true} spacing={1}>
                {userInCardOrNonRattached
                  ? majorTests.map((item: any) => {
                      return (
                        <Grid key={item.id} item={true} xl={4} lg={4} md={4} sm={6} xs={12}>
                          <CardMajorTest
                            testId={item.id}
                            route={item.route}
                            isActive={item.isActive}
                            title={item.name}
                            image={`${publicImgMajorTest}/${item.srcImg}`}
                            time={item.time}
                          />
                        </Grid>
                      );
                    })
                  : majorTests.map((item: any) => {
                      const route = `${
                        language === 'GB' ? getMajorTestEngUrlByTestId(item.id) : item.route
                      }`;
                      return (
                        <Grid key={item.id} item={true} xl={4} lg={4} md={4} sm={6} xs={12}>
                          <ActiveCard
                            testId={item.id}
                            route={route}
                            isActive={item.isActive}
                            title={item.name}
                            image={`${publicImgMajorTest}/${item.srcImg}`}
                            time={item.time}
                          />
                        </Grid>
                      );
                    })}
              </Grid>
            </MarginContainer>
          ) : (
            <>
              <ContentCenter>
                {searchKey?.trim()?.length > 0 ? (
                  <Typography className={classes.colorWhite}>Aucun résultat</Typography>
                ) : (
                  <Typography className={classes.colorWhite}>{noMajorTestText}</Typography>
                )}
              </ContentCenter>
            </>
          )}
        </>
      )}
    </MarginContainer>
  );
};

export default TestMajor;
