/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line simple-import-sort/imports
import { ButtonSomaire, FooterEbook, PageCouverture } from '@orientaction/components';
import Page00 from './Page/00';
import Page01 from './Page/01';
import Page02 from './Page/02';
import Page03 from './Page/03';
import Page04 from './Page/04';
import Page05 from './Page/05';
import Page06 from './Page/06';
import Page07 from './Page/07';
import Page08 from './Page/08';
import Page09 from './Page/09';
import Page10 from './Page/10';

import Page11 from './Page/11';
import Page12 from './Page/12';
import Page13 from './Page/13';
import Page14 from './Page/14';
import Page15 from './Page/15';
import Page16 from './Page/16';
import Page17 from './Page/17';
import Page18 from './Page/18';
import Page19 from './Page/19';
import Page20 from './Page/20';

import Page21 from './Page/21';
import Page22 from './Page/22';
import Page23 from './Page/23';
import Page24 from './Page/24';
import Page25 from './Page/25';
import Page26 from './Page/26';
import Page27 from './Page/27';
import Page28 from './Page/28';
import Page29 from './Page/29';
import Page30 from './Page/30';

import Page31 from './Page/31';
import Page32 from './Page/32';
import Page33 from './Page/33';
import Page34 from './Page/34';
import Page35 from './Page/35';
import Page36 from './Page/36';
import Page37 from './Page/37';
import Page38 from './Page/38';
import Page39 from './Page/39';
import Page40 from './Page/40';

import Page41 from './Page/41';
import Page42 from './Page/42';
import Page43 from './Page/43';

import Page22a from './Page/22a';
import Page33a from './Page/33a';
import Page37a from './Page/37a';

import { useTheme } from '@material-ui/core/styles';
import { LayoutResultat } from '@orientaction/layouts';
import useStyles from './style';
import { useBook3 } from '@orientaction/hooks_book';
import { useEffect, useState } from 'react';
import { useAlert, useRedirectToRootPage } from '@orientaction/hooks';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { useLocation, useParams } from 'react-router-dom';
import { isAdminVerify } from '@orientaction/utils';
import { useSelector } from 'react-redux';
import { appLanguage } from '@orientaction/reducers';

const Book = (props: WithWidth) => {
  const theme = useTheme();

  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  const [readOnly, setReadonly] = useState(true);

  const [users_permissions_user, setUsers_permissions_user] = useState(0);

  const { goToRootPage } = useRedirectToRootPage();

  const { id }: any = useParams();

  const classe = useStyles();

  const lang: string = useSelector(appLanguage);

  const { width } = props;

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const { initValue, getMyValueBook, setId } = useBook3();

  const getValue = async (_id: number) => {
    setLoading(true);

    try {
      const response = await getMyValueBook(id);

      initValue(response.data.value);
      setReadonly(response.data.readOnly);
      setUsers_permissions_user(response.data.value.users_permissions_user.id);
    } catch (err: any) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getValue(+id);
    setId(+id);
  }, [id]);

  return (
    <LayoutResultat
      isWhite={true}
      showLogo={false}
      toBack={
        isAdminVerify()
          ? `/admin/ressources-beneficiaire/${users_permissions_user}`
          : '/mes-ressources'
      }>
      {loading ? (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}>
          <p />
          Chargement en cours...
        </div>
      ) : (
        <div className={classe.readOnly}>
          {/* <PageCouverture src="couverture3.jpg" /> */}
          <Page00 />
          <Page01 />
          <Page02 />
          <Page03 />
          <Page04 readOnly={readOnly} />
          <Page05 />
          <Page06 />
          <Page07 />
          <Page08 />
          <Page09 />
          <Page10 />

          <Page11 />
          <Page12 />
          <Page13 />
          <Page14 />
          <Page15 />
          <Page16 />
          <Page17 />
          <Page18 />
          <Page19 />
          <Page20 />

          <Page21 />
          <Page22 />

          {lang !== 'GB' && <Page22a />}

          <Page23 />
          <Page24 />
          <Page25 />
          <Page26 />
          <Page27 />
          <Page28 />
          <Page29 />
          <Page30 />

          <Page31 />
          <Page32 />
          <Page33 />
          {lang !== 'GB' && <Page33a />}
          <Page34 readOnly={readOnly} />
          <Page35 readOnly={readOnly} />
          <Page36 />
          <Page37 />
          {lang !== 'GB' && <Page37a />}
          <Page38 />
          <Page39 />
          <Page40 />

          <Page41 />
          <Page42 />
          <Page43 />
          <div className={classe.marginFooter}>
            <FooterEbook
              ebookName="l’Étape 3"
              toBack={
                isAdminVerify()
                  ? `/admin/ressources-beneficiaire/${users_permissions_user}`
                  : '/mes-ressources'
              }
            />
          </div>
          <ButtonSomaire href="page-6" />
        </div>
      )}
    </LayoutResultat>
  );
};

export default withWidth()(Book);
