
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textArea: {
            width: "99%",
            height: "80px",
            resize: 'vertical',
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
        },
        textAreaNotBorder: {
            width: "99%",
            display: 'flex',
            border: 'none'
        },
        largeTextField: {
            minHeight: "150px",
        },
        litleTextField:{
            height: "40px",
        },
        veryLitleTextField: {
            height: "16px",
        }
    })
);