import style from './style';
import { Grid } from '@material-ui/core';
import { ReactComponent as Encyclopedie } from '../../../../Asset/svg/encyclopedie.svg';

const ItemEncyclopedie = (props: any) => {
  const classes = style();
  const { text } = props;
  return (
    <div className={classes.container}>
      <Grid className={classes.containerGrey} container={true}>
        <Grid item={true}>
          <Encyclopedie className={classes.icon} />
        </Grid>
        <Grid item={true} xs={true}>
          <div className={classes.text}>{text}</div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ItemEncyclopedie;