import TextField from '@material-ui/core/TextField';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import OaLabel from '@orientaction/commons/OaLabel';
import { AdminTestContext } from '@orientaction/Context';
import classNames from 'classnames';
import { forwardRef, useContext } from 'react';
import Select from 'react-select';
import { restitutionTypes } from './constant';
import style from './style';

const CustomDropdownIndicator = ({ innerRef, innerProps }: any) => (
  <ArrowDropDownIcon innerRef={innerRef} {...innerProps} />
);

const customStyles = (error: boolean) => {
  return {
    control: (provided: any) => ({
      ...provided,
      borderColor: error ? '#f44336' : '#0E1247',
      height: 43,
      boxShadow: 'unset',
      '&:hover': {
        borderColor: error ? '#f44336' : '#0E1247',
      },
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      width: 0,
    }),
  };
};

const TitlePart = forwardRef((_, ref) => {
  const { test, updateTest } = useContext<any>(AdminTestContext);

  const classes = style();
  const styles = customStyles(false);

  const handleChange = (e: any) => {
    updateTest({ test: { ...test.test, title: e.target.value } });
  };

  const handleSelectChange = (value: any) => {
    updateTest({ test: { ...test.test, diagram: value } });
  };

  const hasError = test?.errors?.title;

  // remove simple diagram on edit mode if restitution type is horizontal or vertical
  const options =
    test.isEdit && (test?.test?.diagram?.id === 2 || test?.test?.diagram?.id === 3)
      ? restitutionTypes?.filter((r: any) => r.id !== 1)
      : restitutionTypes;

  return (
    <>
      <div className={classNames(classes.marginTop, classes.fullWidth)}>
        <OaLabel>Titre</OaLabel>
        <TextField
          id="create-test-title"
          variant="outlined"
          className={classNames(classes.formInput, hasError && classes.inputError)}
          name="test-name"
          value={test.test.title}
          onChange={handleChange}
          placeholder="Renseignez le nom"
          ref={ref as any}
        />
      </div>
      <div className={classNames(classes.marginTop, classes.fullWidth)}>
        <OaLabel>Type de restitution</OaLabel>
        <Select
          styles={styles}
          components={{ DropdownIndicator: CustomDropdownIndicator }}
          placeholder="Sélectionnez"
          options={options as any}
          getOptionLabel={option => option.type}
          getOptionValue={option => option.id}
          value={test.test.diagram}
          onChange={handleSelectChange}
          className={classNames({
            [classes.categorySelect]: true,
            [classes.inputError]: false,
          })}
        />
      </div>
    </>
  );
});

export default TitlePart;
