/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr, TitleRomain } from '@orientaction/components';
import { useBook2 } from '@orientaction/hooks_book';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2 } from '../../../../utils/constants';

const Page19 = (props: any) => {
  const classes = useStyles();
  const {
    setResponse1,
    setResponse2,
    setResponse3,
    setResponse4,
    setResponse5,
    setResponse6,
    response1,
    response2,
    response3,
    response4,
    response5,
    response6,
  } = useBook2();
  const [response1_, setResponse1_] = useState<any>(response1);
  const [response2_, setResponse2_] = useState<any>(response2);
  const [response3_, setResponse3_] = useState<any>(response3);
  const [response4_, setResponse4_] = useState<any>(response4);
  const [response5_, setResponse5_] = useState<any>(response5);
  const [response6_, setResponse6_] = useState<any>(response6);
  const question1 = [
    {
      name: '',
      text: 'Je n’ai pas besoin de gagner ma vie',
    },
    {
      name: '',
      text: '+ 1000 euros',
    },
    {
      name: '',
      text: '+ 2000 euros',
    },
    {
      name: '',
      text: '+ 3000 euros',
    },
    {
      name: '',
      text: '+ 5000 euros',
    },
    {
      name: '',
      text: '+ 10000 euros',
    },
    {
      name: '',
      text: '+ 20000 euros',
    },
  ];
  const question2 = [
    {
      name: '',
      text: 'Augmenter votre niveau de vie',
    },
    {
      name: '',
      text: 'Le préserver si possible',
    },
    {
      name: '',
      text: 'Vous êtes prêt(e) à diminuer votre niveau de vie à condition d’avoir un travail dans lequel vous vous épanouissez',
    },
  ];
  const question3 = [
    {
      name: '',
      text: 'Travailler tôt le matin',
    },
    {
      name: '',
      text: 'Travailler tard le soir',
    },
    {
      name: '',
      text: 'Travailler la nuit',
    },
    {
      name: '',
      text: 'Travailler en journée normale',
    },
    {
      name: '',
      text: 'Travailler le week-end',
    },
  ];
  const question4 = [
    {
      name: '',
      text: '20 heures',
    },
    {
      name: '',
      text: 'Entre 35 et 39 heures',
    },
    {
      name: '',
      text: 'Entre 40 et 50 heures',
    },
    {
      name: '',
      text: 'Entre 50 et 60 heures',
    },
    {
      name: '',
      text: 'Vous êtes prêt(e) à travailler plus si nécessaire',
    },
  ];
  const question5 = [
    {
      name: '',
      text: 'Travail uniquement physique',
    },
    {
      name: '',
      text: 'Travail uniquement intellectuel',
    },
    {
      name: '',
      text: 'Travail mixte physique / intellectuel',
    },
  ];
  const question6 = [
    {
      name: '',
      text: 'Travail plutôt avec des déplacements',
    },
    {
      name: '',
      text: 'Travail plutôt sédentaire',
    },
  ];

  const handleChange = (e: any, index: number) => {
    const { name, checked } = e.target;
    if (index === 1) {
      setResponse1_({ [name]: checked });
      setResponse1({ [name]: checked });
    } else if (index === 2) {
      setResponse2_({ [name]: checked });
      setResponse2({ [name]: checked });
    } else if (index === 3) {
      setResponse3_({ [name]: checked });
      setResponse3({ [name]: checked });
    } else if (index === 4) {
      setResponse4_({ [name]: checked });
      setResponse4({ [name]: checked });
    } else if (index === 5) {
      setResponse5_({ [name]: checked });
      setResponse5({ [name]: checked });
    } else if (index === 6) {
      setResponse6_({ [name]: checked });
      setResponse6({ [name]: checked });
    }
  };
  return (
    <Page id={19} readOnly={props.readOnly}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            QUINZE QUESTIONS <br />
            POUR PRÉCISER VOS BESOINS
          </Typography>
        </div>
      </div>
      <div style={{ border: '2px solid grey', padding: '15px' }}>
        <div className={classes.cardBgGrey}>
          <TitleRomain number="I" text="VOS BESOINS PHYSIOLOGIQUES" />
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            number={1}
            text="Quelle somme avez-vous besoin de gagner chaque mois pour vivre ?"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)' }}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question1.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 1)}
                            checked={!!response1_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            number={2}
            text="Dans le cadre du changement que vous envisagez, souhaitez-vous :"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)' }}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question2.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 2)}
                            checked={!!response2_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            number={3}
            text="Concernant vos horaires de travail, vous avez besoin de :"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)' }}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question3.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 3)}
                            checked={!!response3_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            number={4}
            text="Concernant votre volume de travail hebdomadaire, vous avez envie de travailler :"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)' }}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question4.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 4)}
                            checked={!!response4_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            number={5}
            text="Concernant vos conditions de travail, vous avez besoin d’un :"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)' }}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question5.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 5)}
                            checked={!!response5_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            number={6}
            text="Concernant votre volume de travail hebdomadaire, vous avez envie de travailler :"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)' }}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question6.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 6)}
                            checked={!!response6_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
  })
);
export default Page19;
