import OaDivider from '@orientaction/commons/OaDivider';
import { AdminTestContext } from '@orientaction/Context';
import { useContext } from 'react';
import FinalTextSection from './FinalTextSection';
import QuestionsSection from './QuestionsSection';
import TestMainInfo from './TestMainInfo';
import Title from './Title';
import VariablesSection from './VariablesSection';
import VisibilitySection from './VisibilitySection';

const CreateTestForm = () => {
  const { test } = useContext<any>(AdminTestContext);
  const { testTitleRef, testImageRef, testTimeRef, testVisibilityRef } = test?.refs;

  return (
    <>
      <Title />
      <TestMainInfo
        ref={
          {
            titleRef: testTitleRef,
            imageRef: testImageRef,
          } as any
        }
      />
      <VisibilitySection ref={{ timeRef: testTimeRef, visibilityRef: testVisibilityRef } as any} />
      <OaDivider />
      <VariablesSection />
      <OaDivider />
      <QuestionsSection />
      <OaDivider />
      <FinalTextSection />
    </>
  );
};

export default CreateTestForm;
