import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '0 auto',
      [theme.breakpoints.up('xs')]: {
        width: '92.5%',
      },
    },
    input: {
      width: '98%',
      marginLeft: theme.spacing(1),
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        '& .MuiOutlinedInput-root': {
          height: 40,
        },
      },
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    inputXs: {
      display: 'none',
      margin: '2vh 0 2vh 0',
      [theme.breakpoints.up('xs')]: {
        display: 'block',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    inputMd: {
      display: 'block',
      marginTop: 20,
      marginBottom: 10,
    },
    colorWhite: {
      color: 'white',
    },
    bodycenter: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    cardContent: {
      marginRight: '-3%',
      [theme.breakpoints.down(600)]: {
        marginRight: '0%',
      },
    },
    cardContainer: {
      width: '30%',
      marginRight: '3%',
      float: 'left',
      [theme.breakpoints.between(1650, 2000)]: {
        marginBottom: '3.5vh',
      },
      [theme.breakpoints.down(1450)]: {
        width: '48%',
      },
      [theme.breakpoints.down(600)]: {
        float: 'none',
        width: '100%',
      },
    },
    both: {
      clear: 'both',
    },
    searchItem: {
      marginRight: 8,
    },
    searchPaper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginTop: 50,
      [theme.breakpoints.up('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '100%',
      },
    },
    searchInput: {
      marginLeft: theme.spacing(1),
      width: '100%',
    },
    searchIconButton: {
      padding: 10,
    },
  })
);
