/* eslint-disable no-restricted-syntax */
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AdminTestContext } from '@orientaction/Context';
import OaAccordion from '@orientaction/commons/OaAccordion';
import OaPopin from '@orientaction/commons/Popin';
import { useContext, useState } from 'react';
import NoVariable from './NoVariable';
import style from './style';

const VariablesSection = () => {
  const {
    test: {
      test: currentTest,
      variables,
      variableDeletionConfirmPopin,
      errors,
      toDeleteVariablesIds,
      questions,
    },
    updateTest,
  } = useContext<any>(AdminTestContext);

  const [toDeleteVariableIndex, setToDeleteVariableIndex] = useState<any>();

  const classes = style();

  const handleClick = () => {
    updateTest({ variableDrawerOpen: true });
  };

  console.log('varioables', variables);
  console.log('questions', questions);

  const handlePopinConfirm = () => {
    const idx = toDeleteVariableIndex;
    const toDeleteVariable = variables[idx];
    const { isNew, id: toDeleteVariableId } = toDeleteVariable;

    // Mise à jour des questions en filtrant les réponses liées à la variable supprimée
    const updatedQuestions = questions.map((q:any) => ({
      ...q,
      responses: q.responses.map((resp: any) => ({
        ...resp,
        // Supposons que chaque 'variable' dans 'responses' est un tableau d'objets,
        // nous filtrons ce tableau pour exclure la variable supprimée.
        variable: resp.variable.filter((varObj: any) => varObj.id !== toDeleteVariableId),
      })),
    }));

    const newVariables = [...variables.slice(0, idx), ...variables.slice(idx + 1)];

    updateTest({
      variables: newVariables,
      toDeleteVariablesIds: isNew
        ? toDeleteVariablesIds
        : [...toDeleteVariablesIds, toDeleteVariableId],
      questions: updatedQuestions,
      variableDeletionConfirmPopin: {
        open: false,
        title: '',
        handlePopinConfirm: undefined,
        handlePopinClose: undefined,
        data: undefined,
      },
    });
  };
  /* const handlePopinConfirm = () => {
    const idx = toDeleteVariableIndex;
    const toDeleteVariable = variables[idx];
    const { isNew } = toDeleteVariable;
    // loop over questions and delete any responses linked with to delete variables
    const updatedQuestions = [];
    for (const q of questions) {
      const updatedResponses = (q?.responses || [])?.filter(
        (r: any) => r?.variable?.id !== toDeleteVariable?.id,
      );
      q.responses = updatedResponses;
      updatedQuestions.push(q);
    }
    const newVariables = [...variables.slice(0, idx), ...variables.slice(idx + 1)];
    updateTest({
      variables: newVariables,
      toDeleteVariablesIds: isNew
        ? toDeleteVariablesIds
        : [...toDeleteVariablesIds, toDeleteVariable.id],
      questions: updatedQuestions,
      variableDeletionConfirmPopin: {
        open: false,
        title: '',
        handlePopinConfirm: undefined,
        handlePopinClose: undefined,
        data: undefined,
      },
    });
  }; */

  const handlePopinClose = () => {
    updateTest({
      variableDeletionConfirmPopin: {
        open: false,
        title: '',
        handlePopinConfirm: undefined,
        handlePopinClose: undefined,
        data: undefined,
      },
    });
  };

  const handleDelete = (index: number) => (e: any) => {
    e.stopPropagation();
    // open confirm popin
    setToDeleteVariableIndex(index);
    updateTest({
      variableDeletionConfirmPopin: {
        open: true,
        title: 'Confirmation de suppression',
        content:
          'Êtes vous sûr de vouloir supprimer cette variable ? Toutes les réponses qui lui sont liées seront également supprimées définitivement.',
        handlePopinConfirm,
        handlePopinClose,
      },
    });
  };

  const handleEdit = (index: number) => (e: any) => {
    e.stopPropagation();
    updateTest({ variableDrawerOpen: true, variableToEdit: { ...variables[index], index } });
  };

  const OaAccordionDetails = (variable: any) => () => {
    return (
      <p
        className={classes.text}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line react/destructuring-assignment
          __html: variable.description,
        }}
      />
    );
  };
  const isVariableButtonDisabled = currentTest?.diagram?.id === 1 && variables?.length === 2;

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: 35, marginBottom: 15 }}>
        <Typography className={classes.sectionTitle} component="h1">
          Variables
        </Typography>
      </div>
      {variables.length ? (
        variables.map((variable: any, index: number) => (
          <div className={classes.accordionContainer} key={variable.id}>
            <OaAccordion
              summaryText={variable.name}
              detailsText=""
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              index={index}
              accordionStyleOverride={classes.fullWidth}
              accordionDetailsComponent={OaAccordionDetails(variable)}
            />
          </div>
        ))
      ) : (
        <NoVariable hasError={errors?.variables} />
      )}

      <div className={classes.flexContainer}>
        <Button
          disabled={isVariableButtonDisabled}
          onClick={handleClick}
          className={classes.btnPrimary}
        >
          <span className={classes.textHeader}>Ajouter une variable</span>
        </Button>
      </div>
      <OaPopin
        open={variableDeletionConfirmPopin.open}
        title={variableDeletionConfirmPopin.title}
        handleConfirm={handlePopinConfirm}
        handleClose={handlePopinClose}
      >
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalText}>
            {variableDeletionConfirmPopin.content}
          </Typography>
        </div>
      </OaPopin>
    </div>
  );
};

export default VariablesSection;
