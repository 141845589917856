import style from './style';

const ItemCitation = (props: any) => {
  const classes = style();
  const { text, autor } = props;
  return (
    <div className={classes.container}>
      <div>
        <div className={classes.text}>
          <span className={classes.dot}>“</span>
          <span style={{ margin: '0px 3px 0px 7px' }}>{text}</span>
          <span className={classes.dot}>“</span>
        </div>
        <div className={classes.autor}>{autor}</div>
      </div>
    </div>
  );
};

export default ItemCitation;
