/* eslint-disable no-shadow */
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { gameBookTemplateActions } from '@orientaction/api-actions';
import { PermissionSelectGame, SimpleModal } from '@orientaction/components';
import { StatusItemGame } from '@orientaction/utils';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Border } from '../../../../Asset/svg/border.svg';
import { ReactComponent as Check } from '../../../../Asset/svg/check.svg';
import { ReactComponent as Delete } from '../../../../Asset/svg/delete.svg';
import { ReactComponent as Setting } from '../../../../Asset/svg/settings.svg';
import { apiServiceNative } from '../../../../utils/fetchNative';
import style from './style';

interface IItemGame {
  id: string;
  title: string;
  pageNumber: number;
  status: StatusItemGame;
  callBack?: (isOk: boolean) => void;
  options?: any;
  permission?: any;
}

const ItemGame: FC<IItemGame> = ({
  id,
  title,
  pageNumber,
  status,
  callBack,
  options,
  permission,
}) => {
  const classes = style();
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showMessage, setShowmessage] = useState<boolean>(false);

  const dispatch = useDispatch();
  const deleteGame = async () => {
    const deletedGame = await apiServiceNative.delete(`/api/remove-game/${id}`);

    if (callBack && deletedGame.ok) {
      callBack(true);
      setOpenModal(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowmessage(false);
    }, 2000);
  }, [showMessage]);

  return (
    <div className={classes.container}>
      <Grid container={true}>
        <Grid container={true} item={true} sm={5}>
          <Grid item={true} sm={6} className={classes.gridCenterVerticaly}>
            <Typography variant="h4" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item={true} sm={4} className={classes.btnBorder}>
            <Button
              size="small"
              color="primary"
              disableRipple={true}
              className={classes.btn}
              style={{ background: `${StatusItemGame.draft === status ? '#BFC0D5' : '#4C8612'}` }}
              endIcon={StatusItemGame.draft === status ? <Setting /> : <Check />}
            >
              {StatusItemGame.draft === status ? 'Brouillon' : 'Publié'}
            </Button>
          </Grid>
          <Grid item={true} sm="auto" className={classes.gridCenterVerticaly}>
            <Typography variant="h4" className={classes.pageNumber}>
              {pageNumber} pages{' '}
            </Typography>
          </Grid>
        </Grid>
        <Grid item={true} sm={7} className={classes.containerRight}>
          <div className={classes.containerSelect}>
            <PermissionSelectGame
              options={options}
              idGame={id}
              permission={permission}
              handleShow={(show: boolean) => setShowmessage(show)}
            />
            {showMessage && (
              <Typography variant="h5" className={classes.text}>
                Choix sauvegardés
              </Typography>
            )}
          </div>
          <div style={{ paddingLeft: 20 }}>
            <IconButton
              aria-label="delete"
              onClick={() => {
                dispatch(gameBookTemplateActions.resetValues());
                sessionStorage.clear();
                sessionStorage.setItem('id', id);
                history.push(`/admin/game/edit-page/${id}`);
              }}
            >
              <Border />
            </IconButton>

            <IconButton aria-label="delete" onClick={() => setOpenModal(true)}>
              <Delete />
            </IconButton>
          </div>
        </Grid>
      </Grid>
      <SimpleModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title="Information"
        message="Êtes vous sur de vouloir supprimer ce jeu ?"
        textClick="Oui"
        textClose="Non"
        handleClick={deleteGame}
      />
    </div>
  );
};

export default ItemGame;
