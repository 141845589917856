import { Button, Dialog, DialogContent, Grid, IconButton, Slider } from '@material-ui/core';
import { gameBookTemplateActions } from '@orientaction/api-actions';
import html2canvas from 'html2canvas';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as Close } from '../../../../Asset/svg/close.svg';
import { ReactComponent as ZoomIn } from '../../../../Asset/svg/zoom_in.svg';
import { ReactComponent as ZoomOut } from '../../../../Asset/svg/zoom_out.svg';
import { apiServiceNative } from '../../../../utils/fetchNative';
import styles from './style';
import Loader from '../../../../pages/AdminGame/EditPage/loader-edit';

interface IProps {
  fileUrl?: string;
  open: boolean;
  handleClose?: () => void;
  width?: any;
  height?: any;
  handlePosition?: (position: any) => void;
  handleUrl?: (url: any) => void;
  imgHeightB?: any;
  imgWidthB?: any;
  fileOrigin?: any;
}

const ModalEditImg: FC<IProps> = ({
  fileUrl,
  open,
  handleClose,
  width = '100%',
  height = 'auto',
  handlePosition,
  handleUrl,
  imgHeightB,
  imgWidthB,
  fileOrigin,
}) => {
  const classes = styles();
  const LINK_IMG = 'https://storage.googleapis.com/orient-action.appspot.com/';
  const [scale, setScale] = useState<number>(1);
  const [isLong, setIsLong] = useState<any>('square');
  const [position, setPosition] = useState<any>({ x: 0, y: 0 });
  const [loading, setLoading] = useState<boolean>(false);
  const imageRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const dispatch = useDispatch();
  const handleChange = (event: any, newValue: number | number[]) => {
    console.log({ newValue });
    setScale(newValue as number);
  };
  const handleZoomIn = () => {
    const scaleIn = scale + 0.1;
    if (scaleIn > 2) {
      setScale(2);
    } else {
      setScale(scaleIn);
    }
  };
  const handleZoomOut = () => {
    const scaleOut = scale - 0.1;
    if (scaleOut < 1) {
      setScale(1);
    } else {
      setScale(scaleOut);
    }
  };
  useEffect(() => {
    console.log({fileUrl});
    const image = imageRef?.current;
    const container = containerRef?.current;
    const containerBounds = containerRef?.current?.getBoundingClientRect();
    const imageBounds = imageRef?.current?.getBoundingClientRect();
    let isDragging = false;
    let prevPosition = { x: 0, y: 0 };

    const handleMouseDown = (e: any) => {
      isDragging = scale > 1;

      prevPosition = { x: e.clientX, y: e.clientY };
    };

    const handleMouseMove = (e: any) => {
      if (!isDragging) {
        return;
      }
      if (imageBounds.width > containerBounds.width) {
        setPosition((prevPos: any) => {
          console.log('tafiditra');
          const newX = prevPos.x + e.movementX;
          const maxTranslateX = (imageBounds?.width - containerBounds?.width) / 2;
          console.log({ newX, maxTranslateX });
          return {
            x: Math.max(Math.min(newX, maxTranslateX), -maxTranslateX),
            y: prevPos.y,
          };
        });
      }
      if (imageBounds.height > containerBounds.height) {
        setPosition((prevPos: any) => {
          const newY = prevPos.y + e.movementY;
          const maxTranslateY = (imageBounds?.height - containerBounds?.height) / 2;
          return {
            x: prevPos.x,
            y: Math.max(Math.min(newY, maxTranslateY), -maxTranslateY),
          };
        });
      }
      // const deltaX = e.clientX - prevPosition.x;
      // const deltaY = e.clientY - prevPosition.y;
      // if (image.clientHeight > container.clientHeight) {
      //   const imgHeight = imageRef?.current?.clientHeight;
      //   const containHeight = containerRef.current?.clientHeight;
      //   const percentDiff = (imgHeight * 100) / containHeight;
      //   const minPosition = 100 - percentDiff;
      //   const containerHeight = container.clientHeight;
      //   const deltaPercentage = (deltaY / containerHeight) * 100;

      //   prevPosition = { x: e.clientX, y: e.clientY };
      //   setPosition((pos: any) => {
      //     let newPosition = pos.y + deltaPercentage;
      //     console.log({ newPosition, deltaPercentage, pos });
      //     if (newPosition < minPosition) newPosition = minPosition;
      //     if (newPosition > 0) newPosition = 0;
      //     return {
      //       y: newPosition,
      //       x: pos.x,
      //     };
      //   });
      // }

      // if (image.clientWidth > container.clientWidth) {
      //   const imgWidth = imageRef?.current?.clientWidth;
      //   const containWidth = containerRef.current?.clientWidth;
      //   const percentDiffWidth = (imgWidth * 100) / containWidth;
      //   const minPositionWidth = 100 - percentDiffWidth;
      //   const containerWidth = container.clientWidth;
      //   const deltaPercentageWidth = (deltaX / containerWidth) * 100;
      //   prevPosition = { x: e.clientX, y: e.clientY };
      //   setPosition((pos: any) => {
      //     let newPositionX = pos.x + deltaPercentageWidth;

      //     if (newPositionX < minPositionWidth) newPositionX = minPositionWidth;
      //     if (newPositionX > 0) newPositionX = 0;

      //     return {
      //       y: pos.y,
      //       x: newPositionX,
      //     };
      //   });
      // }
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    image?.addEventListener('mousedown', handleMouseDown);
    image?.addEventListener('mousemove', handleMouseMove);
    image?.addEventListener('mouseup', handleMouseUp);

    return () => {
      image?.removeEventListener('mousedown', handleMouseDown);
      image?.removeEventListener('mousemove', handleMouseMove);
      image?.removeEventListener('mouseup', handleMouseUp);
    };
  }, [imageRef, scale, fileUrl]);

  useEffect(() => {
    if (containerRef?.current && imageRef?.current) {
      const containerBounds = containerRef?.current?.getBoundingClientRect();
      const imageBounds = imageRef?.current?.getBoundingClientRect();
      if (imageBounds.width > containerBounds.width) {
        setPosition((prevPos: any) => {
          const newX = prevPos.x;
          const maxTranslateX = (imageBounds?.width - containerBounds?.width) / 2;
          return {
            x: Math.max(Math.min(newX, maxTranslateX), -maxTranslateX),
            y: prevPos.y,
          };
        });
      }
      if (imageBounds.height > containerBounds.height) {
        setPosition((prevPos: any) => {
          const newY = prevPos.y;
          const maxTranslateY = (imageBounds?.height - containerBounds?.height) / 2;
          return {
            x: prevPos.x,
            y: Math.max(Math.min(newY, maxTranslateY), -maxTranslateY),
          };
        });
      }
    }
  }, [scale]);

  const savePosition = () => {
    if (handlePosition) {
      handlePosition({
        scale,
        position: position.y,
      });
      if (handleClose) handleClose();
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    dispatch(gameBookTemplateActions.updateField(field, value));
  };

  function base64ToBlob(base64: string): Blob {
    const mime = 'image/png';
    const sliceSize = 1024;

    const base64Data = base64.split(',')[1] || base64;

    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array<Uint8Array>(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array<number>(end - begin);
      for (let offset = begin, i = 0; offset < end; ++offset, ++i) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    return new Blob(byteArrays, { type: mime });
  }

  const uploadToServer = async (file: any) => {
    const formData = new FormData();
    formData.append('files', file);
    const response = await apiServiceNative.post(`/api/upload`, formData, true);

    dispatch(
      gameBookTemplateActions.updateField('illustration', {
        id: response[0]?.id,
        url: response[0]?.url,
      }),
    );

    let idFILESTemps = localStorage.getItem('idFILESTemps') as any;
    if (!idFILESTemps) {
      idFILESTemps = [];
    } else {
      idFILESTemps = JSON.parse(idFILESTemps);
    }
    idFILESTemps.push(response[0]?.id);
    localStorage.setItem('idFILESTemps', JSON.stringify(idFILESTemps));
  };

  const captureDivToImage = () => {
    if (containerRef.current) {
      if (fileUrl?.includes(LINK_IMG) && scale <= 1) {
        if (handleClose) {
          handleClose();
        }
        return;
      }
      setLoading(true);
      containerRef.current.style.backgroundColor = 'transparent';
      imageRef.current.style.objectFit = 'initial';
      html2canvas(containerRef.current, { useCORS: true }).then(async (canvas) => {
        const imgData = canvas.toDataURL('image/png');
        containerRef.current.style.backgroundColor = '#FAFAFA';
        // dispatch(gameBookTemplateActions.updateField('tempImage', imgData));
        if (handleUrl) {
          await uploadToServer(base64ToBlob(imgData));
          handleUrl(imgData);
          if (handleClose) {
            setScale(1);
            setPosition({ x: 0, y: 0 });
            handleClose();
            setLoading(false);
          }
        }
      });
    }
  };
  const closeModal = async () => {
    if (handleClose) {
      if (fileUrl?.includes(LINK_IMG)) {
        handleClose();
      } else {
        if (handleUrl) {
          handleUrl('');
        }
        handleClose();
      }
      setScale(1);
      setPosition({ x: 0, y: 0 });
    }
  };

  useEffect(() => {
    if (imgHeightB && imgWidthB) {
      if (imgHeightB > imgWidthB) {
        setIsLong('longV');
      }
      if (imgHeightB < imgWidthB) {
        setIsLong('longH');
      }
      if (imgHeightB === imgWidthB) {
        setIsLong('square');
      }
    }
  }, [imgHeightB, imgWidthB, height, width]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.containerPaper}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <DialogContent className={classes.flexContent}>
        <IconButton
          style={{ background: '#E63C3C' }}
          className={classes.btnClose}
          onClick={closeModal}
        >
          <Close className={classes.icon} />
        </IconButton>
        <div style={{ position: 'relative' }}>
          <Grid container={true} spacing={2} justifyContent="center" style={{ marginBottom: 10 }}>
            <Grid item={true}>
              <IconButton
                color="secondary"
                style={{ background: '#000A8C' }}
                onClick={handleZoomOut}
              >
                <ZoomOut className={classes.icon} />
              </IconButton>
            </Grid>
            <Grid item={true} xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Slider
                value={scale}
                min={1}
                step={0.0001}
                max={2}
                onChange={handleChange}
                aria-labelledby="non-linear-slider"
              />
            </Grid>
            <Grid item={true}>
              <IconButton
                color="secondary"
                aria-label="delete"
                style={{ background: '#000A8C' }}
                onClick={handleZoomIn}
              >
                <ZoomIn className={classes.icon} />
              </IconButton>
            </Grid>
          </Grid>
          <div
            ref={containerRef}
            style={{
              width,
              height,
              borderRadius: 5,
              background: '#F1F1F1',
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              ref={imageRef}
              style={{
                width: `${
                  isLong === 'longV'
                    ? 'auto'
                    : isLong === 'square' && width > height
                    ? 'auto'
                    : `100%`
                }`,
                height: `${
                  isLong === 'longH'
                    ? 'auto'
                    : isLong === 'square' && width < height
                    ? 'auto'
                    : `100%`
                }`,
                // top: position.y === null ? '' : `${position.y}%`,
                // left: position.x === null ? '' : `${position.x}%`,
                scale: `${scale}`,
                translate: `${position.x}px ${position.y}px`,
                position: 'absolute',
              }}
              src={fileUrl}
              alt=""
              draggable={false}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            style={{ textTransform: 'none' }}
            onClick={captureDivToImage}
          >
            Valider les changements
          </Button>
        </div>
      </DialogContent>
      <Loader open={loading} text="Upload en cours..." />
    </Dialog>
  );
};
export default ModalEditImg;
