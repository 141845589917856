/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line no-use-before-define
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import start from '@orientaction/Asset/Favorite/start.png';
import { ChipIsDone, ChipTime } from '@orientaction/commons';
import { publicSvgPath, getFromLS, isComingDate } from '@orientaction/utils';
import clsx from 'clsx';
import { kebabCase } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from './style';
import SimpleModal from '../SimpleModal';
import { CircularProgress } from '@material-ui/core';
import { getMe, sendMailForMore } from '../../services/user.service';
import { useSelector } from 'react-redux';
import { appLanguage } from '@orientaction/reducers';

const CardTestSimple = (props: any) => {
  const classes = useStyles();
  const { me, loading } = props;
  const [messageData, setMessageData] = useState<string>(
    'Vous avez atteint la limite de tests offerts gratuitement par ORIENTACTION'
  );
  const [checkingSubscription, setCheckingSubscription] = useState<boolean>(false);

  const paymentLink = process.env.REACT_APP_PAYMENT_LINK;
  const lang: string = useSelector(appLanguage);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const token = getFromLS('userToken') || '';

  const { push } = useHistory();

  const [pastille, showPastille] = useState(false);
  const [loadingMe2, setLoadingMe2] = useState<boolean>(false);

  const redirect = async () => {
    setLoadingMe2(true);
    const dataMe = await getMe(token);
    // const minimum = me.beneficiaryType === 'rattached' ? 10 : 3;
    const minimum = 1;
    if (
      dataMe.data.counterTest === null ||
      dataMe.data.counterTest < minimum ||
      dataMe.data.statusSubscription === 'active'
    ) {
      push(`/test/${props.id}/${kebabCase(props.title)}`);
    } else {
      setOpenModal(true);
    }
    setLoadingMe2(false);
  };

  // useEffect(() => {
  //   if (!loading && me) {
  //     console.log({ me });
  //     setMessageData('Vous avez atteint la limite de tests offerts gratuitement par ORIENTACTION');
  //   }
  // }, [loading, me]);

  useEffect(() => {
    showPastille(!!props.showPastille);
  }, []);

  const sendMail = async () => {
    const resp = await sendMailForMore(me.id, token);
    if (resp) {
      setOpenModal(false);
    }
  };

  const paySubscription = () => {
    window.open(
      `${paymentLink}?prefilled_email=${me.email}&locale=${
        lang === 'GB' ? 'eng' : 'fr'
      }&client_reference_id=${me.id}`,
      '_blank'
    );
    setOpenModal(false);
  };

  return (
    <>
      {checkingSubscription && (
        <div className={classes.bodycenter}>
          <CircularProgress style={{ color: 'white' }} />
        </div>
      )}
      <div className={classes.root} style={{ position: 'relative' }}>
        <Card
          className={clsx({
            [classes.cardContainer]: true,
            [classes.isFavorite]: !!props.isFavorite,
          })}
          onClick={redirect}>
          <Grid container={true} spacing={1}>
            {props.isFavorite ? (
              <Grid item={true} xs={12}>
                <div className={classes.contentImgFavorite}>
                  <div
                    className={classes.imgFavorite}
                    style={{
                      backgroundImage: `url(${
                        props.image && props.image !== '' ? props.image : `${publicSvgPath}/QR.png`
                      })`,
                    }}
                  />
                </div>
              </Grid>
            ) : (
              <Grid item={true}>
                <img
                  className={classes.img}
                  src={props.image && props.image !== '' ? props.image : `${publicSvgPath}/QR.png`}
                  // src={`${publicSvgPath}/QR.png`}
                  alt="logo"
                />
              </Grid>
            )}

            <Grid item={true} xs={true} style={{ position: 'relative' }}>
              <Typography
                color="primary"
                className={clsx({
                  [classes.fontTitleCard]: true,
                  [classes.fontTitleCardFavorite]: !!props.isFavorite,
                })}
                onClick={redirect}>
                <b data-cy="title-card-test">{props.title} </b>
              </Typography>

              <Grid
                container={true}
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Grid>
                  <div
                    className={clsx({
                      [classes.flexDiplay]: true,
                      [classes.flexDisplayFavorite]: !!props.isFavorite,
                    })}>
                    <div>
                      <ChipTime time={props.time} />
                    </div>

                    <div>{pastille && <ChipIsDone />}</div>
                  </div>
                </Grid>

                <Grid>
                  {!!props.isFavorite && (
                    <img src={start} className={classes.start} alt="Favorite" />
                  )}
                </Grid>
              </Grid>

              {props.isFavorite && <div className={classes.bouttom} />}
            </Grid>
          </Grid>
        </Card>
        {loadingMe2 && (
          <div className={classes.bodycenter}>
            <CircularProgress color="primary" />
          </div>
        )}
      </div>
      <SimpleModal
        open={openModal}
        handleClose={sendMail}
        handleClick={paySubscription}
        title="Information"
        message={messageData}
        textClose="Non merci"
        textClick="Débloquer"
      />
    </>
  );
};

export default CardTestSimple;
