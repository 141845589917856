/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography, Button } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import { FC, useState } from 'react';
import { useStyles } from './style';
import SliderScore from '@orientaction/components/SliderScore';
import { dataPreference, publicSvgPath } from '@orientaction/utils';
import { ModalCardPref } from '@orientaction/components';

interface IFirstView {
  listPrincipaux: any[];
}

const FirstView: FC<IFirstView> = ({ listPrincipaux }) => {
  const classe = useStyles();
  console.log({ listPrincipaux });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [pref, setPref] = useState<any>();

  const setValue = (elem: any) => {
    setPref(elem);
    setIsOpen(true);
  };
  return (
    <div className={classe.root}>
      <Grid container={true} spacing={2} className={classe.marginFistViewTop}>
        <Grid item={true} md={7} lg={7} xs={12}>
          <div className={classe.contentNoPadding}>
            <div className={classe.padding25}>
              <Typography
                variant="h4"
                color="primary"
                className={`${classe.title}`}
                style={{ color: 'black', fontSize: 23 }}>
                SYNTHÈSE DE VOS RÉSULTATS
              </Typography>
              <div style={{ marginTop: 15 }}>
                {listPrincipaux.map(elem => (
                  <SliderScore pref={elem} />
                ))}
              </div>
            </div>
          </div>
        </Grid>

        <Grid
          item={true}
          container={true}
          md={5}
          lg={5}
          xs={12}
          direction="column"
          style={{ height: '100%' }}>
          <Grid item={true} xs={12} style={{ paddingBottom: 16 }}>
            <div className={classe.content2}>
              <Typography
                variant="h4"
                color="primary"
                className={`${classe.title}`}
                style={{ color: 'black', fontSize: 23 }}>
                VOS 5 PRÉFÉRENCES
              </Typography>

              <Typography
                variant="body2"
                color="primary"
                className={`${classe.description2}`}
                style={{ marginTop: 25 }}>
                Voici vos cinq préférences dans votre personnalité profonde. La personnalité
                profonde correspond à votre manière de penser et de se comporter quand vous n’êtes
                soumis(e) à aucune contrainte. <br /> <br />
                L’objectif est de trouver un métier et un environnement professionnel qui permettent
                d’exprimer vos préférences profondes. Bref, qui vous permettent d’être vous-même.
                Cela est aussi vrai dans votre vie personnelle.
              </Typography>
              <div className={classe.flexContainer}>
                {listPrincipaux?.map((elem) => (
                  <Button
                    key={elem.category}
                    variant="contained"
                    style={{ background: dataPreference[elem.category].color, color: 'white' }}
                    onClick={() => setValue(elem)}
                    disableElevation={true}>
                    {elem.label}
                  </Button>
                ))}
              </div>
              <Typography
                variant="body2"
                color="primary"
                className={`${classe.description2}`}
                style={{ marginTop: 15, fontStyle: 'italic', color: 'black' }}>
                Cliquez sur une caractéristique pour en savoir + !
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <ModalCardPref
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        color={dataPreference[pref?.category]?.color}
        img={`${publicSvgPath}/${
          dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.img
        }`}
        label={pref?.label}
        title={pref?.title}
        colorSecondary={dataPreference[pref?.category]?.secondColor}
        opacity={!!dataPreference[pref?.category]?.opacity}
        value={!pref?.score[0].isMax ? pref?.score[1].pourcentage : pref?.score[0].pourcentage}
        text1={dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.text1}
        text2={dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.text2}
        list1={dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.list1}
        list2={dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.list2}
        youAre={pref?.youAre}
        listText={
          dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.listText
        }
      />
    </div>
  );
};

export default FirstView;
