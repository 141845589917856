/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable react/button-has-type */

import { gameBookTemplateActions } from '@orientaction/api-actions';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import './TextEditor.css';

interface ITextEditor {
  isSmall?: boolean;
  handleActiveStyles?: (style: any) => void;
  handleFocused?: (focused: boolean) => void;
  styleEditor?: any;
  linkTitleE?: string;
  linkUrlE?: string;
  showLinkPopupE?: boolean;
  reinit?: boolean;
  field: string;
  defaultValue?: any;
}

const TextEditor: React.FC<ITextEditor> = ({
  isSmall = false,
  handleActiveStyles,
  handleFocused,
  styleEditor,
  linkTitleE,
  linkUrlE,
  showLinkPopupE,
  reinit,
  field,
  defaultValue = '',
}) => {
  const [content, setContent] = useState<string>('');
  const [activeStyles, setActiveStyles] = useState<Record<string, any>>({});
  const [showLinkPopup, setShowLinkPopup] = useState<boolean>(true);
  const [linkTitle, setLinkTitle] = useState<string>('');
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [lineHeightState, setLine] = useState<any>('1.75'); // Valeur par défaut mise à jour
  const editorRef = useRef<HTMLDivElement>(null);
  const [first, setFirst] = useState<boolean>(false);
  const styleToggle = [
    'bold',
    'italic',
    'underline',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'insertUnorderedList',
    'insertOrderedList',
  ];

  const savedSelection = useRef<Range | null>(null);
  const dispatch = useDispatch();

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      savedSelection.current = selection.getRangeAt(0);
    }
  };

  const restoreSelection = () => {
    const selection = window.getSelection();
    if (savedSelection.current && selection) {
      selection.removeAllRanges();
      selection.addRange(savedSelection.current);
    }
  };

  const applyStyle = (styleCommand: string, arg?: string) => {
    saveSelection();

    if (styleCommand === 'reset') {
      document.execCommand('removeFormat', false, '');
      document.execCommand('unlink', false, '');
      if (editorRef.current) {
        editorRef.current.style.lineHeight = '';
      }
      setActiveStyles({});
      return;
    }

    if (styleCommand === 'lineHeight') {
      if (editorRef.current) {
        restoreSelection();
        setLine(arg);
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        const selectedText = range?.toString();
        if (selectedText) {
          const span = document.createElement('span');
          span.style.lineHeight = arg || '';
          span.style.display = 'inline-block';
          span.style.width = '100%';
          span.style.marginTop =
            arg === '1.15'
              ? '0.05em'
              : arg === '1.75'
              ? '0.075em'
              : arg === '2'
              ? '0.1em'
              : arg === '3'
              ? '0.2em'
              : '0';
          span.style.marginBottom =
            arg === '1.15'
              ? '0.05em'
              : arg === '1.75'
              ? '0.075em'
              : arg === '2'
              ? '0.1em'
              : arg === '3'
              ? '0.2em'
              : '0';
          span.textContent = selectedText;
          document.execCommand('insertHTML', false, span.outerHTML);
          setActiveStyles({ ...activeStyles, lineHeight: arg });
        }
      }
    } else if (styleCommand.startsWith('justify')) {
      if (activeStyles[styleCommand]) {
        document.execCommand('justifyLeft', false, '');
        setActiveStyles({ ...activeStyles, [styleCommand]: false });
      } else {
        document.execCommand(styleCommand, false, arg || '');
        setActiveStyles({
          ...activeStyles,
          justifyLeft: false,
          justifyCenter: false,
          justifyRight: false,
          [styleCommand]: true,
        });
      }
    } else if (styleCommand === 'insertUnorderedList' || styleCommand === 'insertOrderedList') {
      if (activeStyles[styleCommand]) {
        document.execCommand('insertParagraph', false, '');
        setActiveStyles({ ...activeStyles, [styleCommand]: false });
      } else {
        document.execCommand(styleCommand, false, arg || '');
        setActiveStyles({
          ...activeStyles,
          insertUnorderedList: false,
          insertOrderedList: false,
          [styleCommand]: true,
        });
      }
    } else if (styleCommand === 'formatBlock' && arg) {
      removeCurrentBlockFormat();
      document.execCommand(styleCommand, false, arg);
      setActiveStyles({ ...activeStyles, formatBlock: arg });
      if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(arg.toLowerCase())) {
        const elements = editorRef.current?.getElementsByTagName(arg.toLowerCase()) as any;
        if (elements) {
          for (const element of Array.from(elements) as any) {
            element.style.fontWeight = 'normal';
          }
        }
      }
    } else {
      document.execCommand(styleCommand, false, arg || '');
      updateActiveStyles();
    }

    restoreSelection();
  };

  const removeCurrentBlockFormat = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer;
    let parentBlock = container.parentElement;
    while (parentBlock && !parentBlock.matches('div, p, h1, h2, h3, h4, h5, h6')) {
      parentBlock = parentBlock.parentElement;
    }
    if (parentBlock && parentBlock.matches('h1, h2, h3, h4, h5, h6')) {
      const fragment = document.createDocumentFragment();
      while (parentBlock.firstChild) {
        fragment.appendChild(parentBlock.firstChild);
      }
      parentBlock.parentNode?.replaceChild(fragment, parentBlock);
    }
  };

  const resetSelectedStyle = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const range = selection.getRangeAt(0);

    const contentx = range.extractContents();
    const plainText = contentx.textContent || '';

    range.deleteContents();
    range.insertNode(document.createTextNode(plainText));

    selection.removeAllRanges();
    const newRange = document.createRange();
    newRange.selectNodeContents(range.commonAncestorContainer);
    selection.addRange(newRange);

    document.execCommand('removeFormat', false, '');
    document.execCommand('unlink', false, '');
    if (editorRef.current) {
      editorRef.current.style.lineHeight = '';
    }
    setActiveStyles({});
    updateActiveStyles();
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  const getCurrentLineHeight = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return '1.75'; // Valeur par défaut mise à jour
    const range = selection.getRangeAt(0);
    const { parentElement } = range.commonAncestorContainer;

    if (parentElement) {
      const computedStyle = window.getComputedStyle(parentElement);
      return computedStyle.lineHeight;
    }
    return '1.75'; // Valeur par défaut mise à jour
  };

  const updateActiveStyles = () => {
    const styles: Record<string, boolean | string> = {
      bold: document.queryCommandState('bold'),
      italic: document.queryCommandState('italic'),
      underline: document.queryCommandState('underline'),
      justifyLeft: document.queryCommandState('justifyLeft'),
      justifyCenter: document.queryCommandState('justifyCenter'),
      justifyRight: document.queryCommandState('justifyRight'),
      insertOrderedList: document.queryCommandState('insertOrderedList'),
      insertUnorderedList: document.queryCommandState('insertUnorderedList'),
      link: document.queryCommandState('createLink'),
      formatBlock: document.queryCommandValue('formatBlock').toLowerCase(),
      lineHeight: getCurrentLineHeight(),
    };

    setActiveStyles(styles);
  };

  const handleContentChange = () => {
    if (editorRef.current) {
      setContent(editorRef.current.innerHTML);
      dispatch(gameBookTemplateActions.updateField(field, editorRef.current.innerHTML));
    }
    updateActiveStyles();
  };

  const handleInsertLink = (e: any) => {
    restoreSelection();
    e.stopPropagation();

    if (linkTitle && linkUrl) {
      const linkHTML = `<a href="${linkUrl}" target="_blank">${linkTitle}</a>`;
      document.execCommand('insertHTML', false, linkHTML);
    }
    if (handleFocused) handleFocused(true);
    setShowLinkPopup(false);
    setLinkTitle('');
    setLinkUrl('');
    handleContentChange();
    setActiveStyles({ ...activeStyles, link: false });
  };

  useEffect(() => {
    if (!editorRef.current) return;

    const handleBackspace = (event: any) => {
      if (event.key === 'Backspace') {
        event.preventDefault();
        if (editorRef.current?.innerText.trim().length === 0) {
          editorRef.current.innerHTML = '&#8203;';
        } else {
          document.execCommand('delete', false);
        }
      }
    };

    const handleEnter = (event: any) => {
      if (event.key === 'Enter') {
        if (editorRef.current) {
          document.execCommand('defaultParagraphSeparator', false, 'div');
        }
      }
    };

    const handleSelectionChange = () => {
      updateActiveStyles();
    };

    document.addEventListener('selectionchange', handleSelectionChange);

    editorRef.current.addEventListener('keydown', handleBackspace);
    editorRef.current.addEventListener('keydown', handleEnter);

    editorRef.current.addEventListener('input', () => {
      handleContentChange();
    });

    updateActiveStyles();

    // Clean up function to remove event listener
    return () => {
      if (editorRef.current) {
        editorRef.current.removeEventListener('keydown', handleBackspace);
        editorRef.current.removeEventListener('keydown', handleEnter);
      }
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  useEffect(() => {
    if (styleEditor) {
      if (styleEditor.styleCommand === 'reset') {
        resetSelectedStyle();
      } else if (styleToggle.includes(styleEditor.styleCommand)) {
        toggleStyle(styleEditor.styleCommand);
      } else {
        applyStyle(styleEditor.styleCommand, styleEditor.arg);
      }
    }
  }, [styleEditor]);

  useEffect(() => {
    if (showLinkPopupE === undefined || showLinkPopupE === null) {
      return;
    }
    saveSelection();
    setShowLinkPopup(!showLinkPopup);
  }, [showLinkPopupE]);

  useEffect(() => {
    if (handleActiveStyles) {
      handleActiveStyles(activeStyles);
    }
  }, [activeStyles]);

  useEffect(() => {
    if (editorRef.current && !first) {
      editorRef.current.innerHTML = defaultValue;

      setContent(defaultValue);
      updateActiveStyles();
      saveSelection();
    }
  }, [defaultValue, first]);

  const toggleStyle = (styleCommand: string) => {
    if (activeStyles[styleCommand]) {
      document.execCommand(styleCommand, false, 'false');
      setActiveStyles({ ...activeStyles, [styleCommand]: false });
    } else {
      document.execCommand(styleCommand, false, 'true');
      setActiveStyles({ ...activeStyles, [styleCommand]: true });
    }
  };

  return (
    <div className="editor-container">
      <div className="toolbar">
        <button className={activeStyles.bold ? 'active' : ''} onClick={() => toggleStyle('bold')}>
          Gras
        </button>
        <button
          className={activeStyles.italic ? 'active' : ''}
          onClick={() => toggleStyle('italic')}
        >
          Italique
        </button>
        <button
          className={activeStyles.underline ? 'active' : ''}
          onClick={() => toggleStyle('underline')}
        >
          Souligné
        </button>
        <button
          className={activeStyles.justifyLeft ? 'active' : ''}
          onClick={() => toggleStyle('justifyLeft')}
        >
          Aligner à gauche
        </button>
        <button
          className={activeStyles.justifyCenter ? 'active' : ''}
          onClick={() => toggleStyle('justifyCenter')}
        >
          Centrer
        </button>
        <button
          className={activeStyles.justifyRight ? 'active' : ''}
          onClick={() => toggleStyle('justifyRight')}
        >
          Aligner à droite
        </button>
        <button
          className={activeStyles.insertUnorderedList ? 'active' : ''}
          onClick={() => toggleStyle('insertUnorderedList')}
        >
          Bullet Points
        </button>
        <button
          className={activeStyles.insertOrderedList ? 'active' : ''}
          onClick={() => toggleStyle('insertOrderedList')}
        >
          Numérotation
        </button>

        <select
          value={activeStyles.formatBlock}
          onChange={(e) => {
            const newFormatBlock = e.target.value;
            // Remove the current block format
            removeCurrentBlockFormat();
            // Apply the new format block
            applyStyle('formatBlock', newFormatBlock);
            if (lineHeightState) {
              applyStyle('lineHeight', lineHeightState);
            }
          }}
        >
          <option value="div">Paragraphe</option>
          <option value="h6" style={{ fontWeight: 'normal' }}>
            Très Petit
          </option>
          <option value="h5" style={{ fontWeight: 'normal' }}>
            Petit
          </option>
          <option value="h4" style={{ fontWeight: 'normal' }}>
            Moyen
          </option>
          <option value="h3" style={{ fontWeight: 'normal' }}>
            Grand
          </option>
          <option value="h2" style={{ fontWeight: 'normal' }}>
            Sous-titre
          </option>
          <option value="h1" style={{ fontWeight: 'normal' }}>
            Très grand
          </option>
        </select>

        <select value={lineHeightState} onChange={(e) => applyStyle('lineHeight', e.target.value)}>
          <option value="1.15">1</option>
          <option value="1.75">1.15</option>
          <option value="2">1.5</option>
          <option value="3">2</option>
        </select>

        <button
          onClick={() => {
            saveSelection();
            setShowLinkPopup(true);
          }}
          style={{ display: 'none' }}
        >
          Insérer un lien
        </button>
        <button onClick={resetSelectedStyle}>Réinitialiser le style sélectionné</button>
      </div>
      <div
        ref={editorRef}
        contentEditable={true}
        placeholder="Cliquer pour ajouter du texte"
        className="editable-area-not-choice"
        onInput={handleContentChange}
        style={{ minHeight: isSmall ? 156 : 334, lineHeight: '1.75' }}
        onClick={(e) => {
          setFirst(true);
          e.stopPropagation();
          if (handleFocused) handleFocused(true);
        }}
        onFocus={() => {
          if (handleFocused) handleFocused(true);
          if (handleActiveStyles) handleActiveStyles(activeStyles);
        }}
        onChange={(e) => {
          if (handleFocused) handleFocused(true);
        }}
        onPaste={handlePaste}
      />

      <button
        onClick={() => {
          saveSelection();
          setShowLinkPopup(true);
        }}
        style={{ display: 'none' }}
      >
        Insérer un lien
      </button>
      <button style={{ display: 'none' }} onClick={resetSelectedStyle}>
        Réinitialiser le style sélectionné
      </button>

      {showLinkPopup && (
        <div
          className="link-popup"
          onClick={(e) => e.stopPropagation()}
          onFocus={() => {
            if (handleFocused) handleFocused(true);
          }}
        >
          <input
            type="text"
            value={linkTitle}
            onChange={(e) => setLinkTitle(e.target.value)}
            placeholder="Entrer le titre du lien"
            onFocus={() => {
              if (handleFocused) handleFocused(true);
            }}
          />
          <input
            type="text"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
            placeholder="Entrer l'URL du lien"
            onFocus={() => {
              if (handleFocused) handleFocused(true);
            }}
          />
          <button style={{ marginRight: 2 }} onClick={handleInsertLink}>
            Insérer
          </button>
          <button
            onClick={() => {
              setShowLinkPopup(false);
              setLinkTitle('');
              setLinkUrl('');
            }}
          >
            Annuler
          </button>
        </div>
      )}
    </div>
  );
};

export default TextEditor;
