const restitutionTypes = [
  {
    id: 1,
    type: 'Diagramme en secteur - simple',
  },
  {
    id: 2,
    type: 'Diagramme en secteur',
  },
  {
    id: 3,
    type: 'Diagramme horizontal',
  },
];

export { restitutionTypes };
