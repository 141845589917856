import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  gridCenterVerticaly: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
    overflow: 'hidden',
    textAlign: 'left',
    background: '#FAFAFA',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    '& *': {
      fontFamily: 'Poppins',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'left',
      borderRadius: 0,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      padding: '0px 10px',
      '& *': {
        margin: '10px 0px',
      },
    },
  },
  title2: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'none',
    fontStyle: 'italic',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  img: {
    width: '25%',
  },
  container2: {
    display: 'flex',
    alignItems: 'center',
    textWrap: 'nowrap',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },
  container3: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '25px 21px 24px',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    justifyContent: 'center',
    height: 'calc(100% - 49px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'left',
      borderRadius: 0,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      padding: '7px',
      height: 'auto',
    },
  },
  borderSelected: {
    border: '1px solid #B0B5FF',
    borderRadius: 5,
  },
  borderTransparent: {
    border: '1px solid transparent',
    borderRadius: 5,
  },
  borderSelectedLastPage: {
    border: '1px solid #E63C3C',
    borderRadius: 5,
  },
  textChoice: {
    padding: '1px 1px 1px 0px',
    height: 'calc(100% - 2px)',
    display: 'flex',
    alignItems: 'center',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    width: 'calc(100% - 40px)',
    '& *': {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 2px)',
      textAlign: 'left',
      borderRadius: 0,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      padding: '0px 1px 1px 1px',
      height: 'auto',
    },
  },
  separator: {
    height: '1px',
    width: '100%',
    background: '#E1E1E1',
    marginBottom: 10,
  },
}));
