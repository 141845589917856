import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BorderAll } from '@material-ui/icons';

export default makeStyles((theme: Theme) => ({
  btnPrimary: {
    background: '#000A8C',
    textTransform: 'none',
  },
  separator: {
    margin: '20px 0px',
    width: '100%',
    position: 'relative',
    height: '2px',
    background: '#E4E4F2',
    display: 'block',
  },
  formInput: {
    width: '100%',
    '& div fieldset': {
      borderColor: '#E4E4F2',
      top: '-6px',
    },
    '& div input': {
      paddingTop: 12,
      paddingBottom: 12,
      fontSize: 14,
    },
  },
  label: {
    fontSize: 14,
    marginBottom: 10,
  },
  card: {
    padding: '20px 30px',
    background: 'white',
    borderLeft: '10px solid #E6E6E6',
    borderRadius: 5,
  },
  closeBtn: {
    fontSize: 26,
    margin: 8,
    border: '1px solid #E4E4F2',
    borderRadius: '50%',
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    cursor: 'pointer',
  },
  section1: {
    background: '#F1F1F1',
    padding: '15px 15px 4px',
    borderRight: '1px solid #E4E4F2',
    borderBottom: '1px solid #E4E4F2',
    height: '100%',
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingBottom: '4px',
  },
  section2: {
    height: 'auto',
    borderBottom: '1px solid #E4E4F2',
    background: '#F1F1F1',
    display: 'flex',
    alignItems: 'center',
  },
  section3: {
    minHeight: '90vh',
    borderRight: '1px solid #E4E4F2',
    background: '#F1F1F1',
    maxHeight: '90vh',
    height: '90vh',
  },
  section4: {
    background: '#F7F7FB',
    minHeight: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
  },
  icon: {
    width: 15,
    height: 15,
  },
  title2: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
  },
  wrapbutton: {
    position: 'relative',
    paddingBottom: '4px',
    '&:hover $tooltipLabel': {
      visibility: 'visible',
      opacity: 1,
    },

    '&.active $iconButton': {
      background: 'none',
      border: '1px solid #E63C3C',
      padding: '0',
      '& svg': {
        '& path': {
          fill: '#E63C3C',
        },
      },
    },

    '&:first-child': {
      '& $tooltipLabel': {
        width: '140px',
        left: '-30px',
        transform: 'none',
      },
    },

    '&:nth-of-type(2)': {
      '& $tooltipLabel': {
        width: '165px',
      },
    },
  },
  tooltipLabel: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 800,
    backgroundColor: '#E63C3C',
    color: 'white',
    padding: '10px 12px 9px',
    borderRadius: theme.shape.borderRadius,
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.5s linear',
    zIndex: 1,
    cursor: 'pointer',
  },
  iconButton: {
    position: 'relative',
    width: '39px',
    height: '39px',
    borderRadius: '5px',
    '&:hover': {
      background: 'none',
      border: '1px solid #E63C3C',
      padding: '0',
      '& svg': {
        '& path': {
          fill: '#E63C3C',
        },
      },
    },
  },
  iconS: {
    fontSize: 17,
  },
  containerIconS: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  containerPageNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  formInputNumber: {
    background: 'transparent',
    '& div fieldset': {
      borderColor: 'transparent',
      top: '-6px',
    },
    '& div input': {
      paddingTop: 12,
      paddingBottom: 12,
      fontSize: 14,
      textAlign: 'center',
      width: 22,
    },
    '& .MuiOutlinedInput-root': {
      background: 'transparent',
    },
  },
  containerGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  iconbig: {
    width: 19,
    height: 19,
  },
  btnFull: {
    borderRadius: 5,
    color: '#000A8C',
    width: '188px',
    padding: '15px 25px',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  container: {
    padding: '1px',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #E4E4F2',
    cursor: 'pointer',
  },

  textFooter: {
    color: '#B0B5FF',
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '14px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnOutFilled: {
    borderRadius: 5,
    color: 'white',
    width: '100%',
    border: '1px solid white',
    textTransform: 'none',
  },
  title: {
    color: '#000A8C',
    textAlign: 'center',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'none',
    lineHeight: 1,
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      marginTop: 12,
      fontSize: '18px',
      padding: '0px 10px',
    },
  },
  titleStatus: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'italic',
    fontWeight: 600,
    lineHeight: '14px',
    margin: '5px 0px',
  },
  containerCover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    borderRadius: 5,
    borderLeft: '12px solid #757788',
    padding: '141% 0px 0px',
    position: 'relative',
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
    },
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerElement: {
    height: '80%',
    width: '80%',
    borderRadius: 5,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },
  spanText: {
    color: '#FFF',
    textAlign: 'right',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '14px',
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white',
    textTransform: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    left: '40px',
    [theme.breakpoints.down('xs')]: {
      top: '25px',
      left: 'auto',
    },
  },
  btn2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white',
    textTransform: 'none',
    backgroundColor: 'transparent',
    position: 'relative',
    margin: '30px auto 25px',
    maxWidth: '1280px',
    flex: 1,
  },
  containerDiv: {
    position: 'relative',
    padding: 20,
    margin: '20px 0px 40px',
  },
  containerModel: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 0px 50px',
    padding: '0px 15px',
  },
  colorPrimary: {
    backgroundColor: '#000A8C',
    height: '15px',
    borderRadius: '60px',
    [theme.breakpoints.down('xs')]: {
      height: '8px',
    },
  },
  barColorPrimary: {
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    height: '15px',
    borderRadius: '60px',
    [theme.breakpoints.down('xs')]: {
      height: '8px',
    },
  },
  progressBar: {
    width: '50%',
    margin: '30px auto auto auto',
    maxWidth: '1280px',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto auto 20px auto',
      width: '60%',
    },
  },
  btnHeader: {
    display: 'flex',
  }
}));
