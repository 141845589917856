import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
  rootSlider: {
    height: 10,
    color: (props: any) => props.color,
    padding: '5px 0px',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    display: 'none',
  },
  active: {},
  track: {
    height: 10,
    borderRadius: 50,
  },
  rail: {
    height: 10,
    borderRadius: 50,
    backgroundColor: '#bfbfbf',
  },
  widthSlider: {
    padding: '10px 15px 0px',
    [theme.breakpoints.down(800)]: {
      padding: '10px 0px 0px',
    },
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  hiddeDiv: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
    top: 0,
  },
  textHeader: {
    width: '100%',
    textAlign: 'center',
    color: '#242424',
    marginBottom: 10,
  },
  prefCard: {
    padding: '15px 0px',
    borderBottom: '1px solid #DBDBDB',
    '&:last-child': {
      borderBottom: 'none !important',
    },
  },
  boldMedium: {
    '& b': {
      fontWeight: '600 !important',
    },
  },
}));

export default useStyles;
