/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-useless-return */
import { Grid, IconButton } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ReactComponent as AlignLeft } from '../../../../Asset/svg/format_align_left.svg';
import { ReactComponent as Bold } from '../../../../Asset/svg/format_bold.svg';
import { ReactComponent as Italic } from '../../../../Asset/svg/format_italic.svg';
import { ReactComponent as LineSpacing } from '../../../../Asset/svg/format_line_spacing.svg';
import { ReactComponent as ListBullet } from '../../../../Asset/svg/format_list_bulleted.svg';
import { ReactComponent as ListNumber } from '../../../../Asset/svg/format_list_numbered.svg';
import { ReactComponent as Underline } from '../../../../Asset/svg/format_underlined.svg';
import { ReactComponent as Linked } from '../../../../Asset/svg/link.svg';
import { ReactComponent as Remove } from '../../../../Asset/svg/remove_format.svg';
import style from './style';

const BarEdition = (props: any) => {
  const { stateEdition, editState, maxPage, changePageBarEdition, pageData, handleLink } = props;
  const classes = style();
  const [fontSize, setFontSize] = useState<string>('12');
  const [currentPage, setPage] = useState<any>(1);
  const [currentPolice, setPolice] = useState<any>(1);
  const [showLinkPopup, setShowLinkPopup] = useState<boolean>(true);
  const [forceUpdate, setForceUpdate] = useState(0);

  useEffect(() => {
    // console.log({ stateEdition });
    if (pageData > 0) {
      setPage(pageData);
    }
  }, [stateEdition, pageData]);

  const handleShow = () => {
    setShowLinkPopup(!showLinkPopup);
    handleLink(showLinkPopup);
  };

  const valueDefault = (state: any) => {
    if (state.justifyLeft) {
      return 'justifyLeft';
    }
    if (state.justifyRight) {
      return 'justifyRight';
    }
    if (state.justifyCenter) {
      return 'justifyCenter';
    }
    return '';
  };

  const valueDefaultLineHeight = (state: any) => {
    switch (state.lineHeight) {
      case '18.4px':
        return '1.15';

      case '28px':
        return '1.75';

      case '32px':
        return '2';

      case '48px':
        return '3';

      default:
        break;
    }
  };

  const valueDefaultSelect = (state: any) => {
    if (state.formatBlock === 'div') {
      return 'div';
    }
    return state.formatBlock;
  };

  return (
    <>
      <Grid container={true}>
        <Grid item={true} sm={2} className={classes.sectionFlex}>
          {/* <div className={classes.sectionFlexContent} style={{ border: 'none' }}>
            <select
              className={classes.selectLine}
              style={{ marginLeft: 0 }}
              onChange={(e) => editState('formatBlock', e.target.value)}
            >
              <option value="p" style={{ fontSize: '16px', fontWeight: 'normal' }}>
                Normal
              </option>
              <option value="h1" style={{ fontSize: '32px', fontWeight: 'bold' }}>
                Titre Principal
              </option>
              <option value="h2" style={{ fontSize: '28px', fontWeight: 'bold' }}>
                Sous-titre
              </option>
              <option value="h3" style={{ fontSize: '24px', fontWeight: 'bold' }}>
                Sous-sous-titre
              </option>
              <option value="h4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                Titre de section
              </option>
            </select>
          </div> */}

          <div className={classes.sectionFlexContent} style={{ border: 'none' }}>
            <IconButton
              aria-label="delete"
              className={stateEdition?.bold ? classes.active : ''}
              onClick={() => editState('bold')}
            >
              <Bold className={classes.icon} />
            </IconButton>
            <IconButton
              aria-label="save"
              className={stateEdition?.italic ? classes.active : ''}
              onClick={() => editState('italic')}
            >
              <Italic className={classes.icon} />
            </IconButton>
            <IconButton
              aria-label="delete"
              className={stateEdition?.underline ? classes.active : ''}
              onClick={() => editState('underline')}
            >
              <Underline className={classes.icon} />
            </IconButton>
            <IconButton
              className={stateEdition?.link ? classes.active : ''}
              aria-label="delete"
              onClick={handleShow}
            >
              <Linked className={classes.icon} />
            </IconButton>
          </div>
          <div className={classes.sectionFlexContent} style={{ padding: '0px 20px' }}>
            <IconButton aria-label="delete" style={{ borderRadius: 5 }}>
              <AlignLeft className={classes.icon} />
              <select
                className={classes.selectLine}
                value={valueDefault(stateEdition)}
                onChange={(e) => editState(e.target.value)}
              >
                <option value="justifyLeft">Gauche</option>
                <option value="justifyCenter">Centré</option>
                <option value="justifyRight">Droite</option>
              </select>
            </IconButton>
            <IconButton aria-label="save" style={{ borderRadius: 5 }}>
              <LineSpacing className={classes.icon} />
              <select
                className={classes.selectLine}
                value={valueDefaultLineHeight(stateEdition)}
                onChange={(e) => {
                  setForceUpdate((prev) => prev + 1);
                  editState('lineHeight', e.target.value);
                }}
              >
                <option value="1.15">1</option>
                <option value="1.75">1.15</option>
                <option value="2">1.5</option>
                <option value="3">2</option>
              </select>
            </IconButton>
            <IconButton
              className={stateEdition?.insertOrderedList ? classes.active : ''}
              aria-label="delete"
              onClick={() => editState('insertOrderedList')}
            >
              <ListNumber className={classes.icon} />
            </IconButton>
            <IconButton
              className={stateEdition?.insertUnorderedList ? classes.active : ''}
              aria-label="delete"
              onClick={() => editState('insertUnorderedList')}
            >
              <ListBullet className={classes.icon} />
            </IconButton>
          </div>

          <div
            className={classes.sectionFlexContent}
            style={{ border: 'none', alignItems: 'center' }}
          >
            <span className={classes.textSize}>Taille du texte :</span>
            <select
              className={classes.selectLine}
              style={{ height: '60%' }}
              value={valueDefaultSelect(stateEdition) || 'div'}
              onChange={(e) => editState('formatBlock', e.target.value)}
            >
              {' '}
              <option value="h5">Petit </option>
              <option value="div">Normal</option>
              <option value="h2">Grand</option>
            </select>
          </div>

          <div className={classes.sectionFlexContent} style={{ border: 'none' }}>
            <IconButton
              aria-label="delete"
              onClick={() => {
                editState('reset', '');
              }}
            >
              <Remove className={classes.icon} />
            </IconButton>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default BarEdition;
