import { gameBookTemplateActions } from '@orientaction/api-actions';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import style from './style';

interface IEditorEncyclopedie {
  handleChange?: (infoImg: any) => void;
  defaultValue?: string;
  fieldUpdate?: string;
  minHeight?: any;
}
const EditorEncyclopedie: FC<IEditorEncyclopedie> = ({
  handleChange,
  defaultValue = '',
  fieldUpdate = 'field',
  minHeight = 334,
}) => {
  const classes = style();
  const [value, setValue] = useState<string>(defaultValue);
  const textareaRef = useRef(null);
  const dispatch = useDispatch();
  const handleChangeCitation = (e: any) => {
    setValue(e.target.value);
    handleFieldChange(fieldUpdate, e.target.value);
    if (handleChange) {
      handleChange(e.target.value);
    }
  };

  const pastText = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text');
    const newValue = `${value ?? ''}${text} `;
    setValue(newValue);
    handleFieldChange(fieldUpdate, newValue);
    if (handleChange) {
      handleChange(newValue);
    }
    setTimeout(() => {
      const event = new Event('input', { bubbles: true });
      (textareaRef.current as any)?.dispatchEvent(event);
    }, 0);
  };

  useEffect(() => {
    const textRef: any = textareaRef.current;
    if (textRef) {
      textRef.style.height = 'auto';
      textRef.style.height = `${textRef.scrollHeight - 30}px`;
    }
  }, [value]);

  const handleFieldChange = (field: string, data: any) => {
    dispatch(gameBookTemplateActions.updateField(fieldUpdate, data));
  };

  return (
    <div style={{ height: '100%' }}>
      <textarea
        ref={textareaRef}
        style={{ minHeight }}
        className={classes.editArea}
        placeholder="Cliquer pour ajouter une information"
        value={value}
        onPaste={pastText}
        onInput={handleChangeCitation}
      />
    </div>
  );
};

export default EditorEncyclopedie;
