import { Grid, Typography } from '@material-ui/core';
import { AdminTestContext } from '@orientaction/Context';
import { FC, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import style from './style';

const Item: FC<any> = ({ response }) => {
  const { test } = useContext<any>(AdminTestContext);
  const classes = style();
  // fetch variable name from variable object to always get the latest update

  const variableName = (test?.variables || [])
    .filter((v: any) => {
      // S'assurer que response?.variable est traité comme un tableau, même s'il s'agit d'un seul objet ou est undefined/null
      const responseVariables = response?.variable;
      const variablesArray = Array.isArray(responseVariables)
        ? responseVariables
        : responseVariables
        ? [responseVariables]
        : [];

      return variablesArray.some((rv: any) => rv?.id === v?.id);
    })
    .map((v: any) => v?.name)
    .join(', ');

  return (
    <>
      <Grid item={true} xl={12} sm={12} md={12}>
        <Typography className={classes.responseTitle} component="p">
          {ReactHtmlParser(response.title)}
        </Typography>
      </Grid>
      <Grid container={true}>
        <Grid item={true} sm={6} md={10}>
          <Typography className={classes.variableNameLabel} component="span">
            Variables liées:
          </Typography>
          <Typography className={classes.variableNameText} component="span">
            {variableName}
          </Typography>
        </Grid>
        <Grid item={true} sm={6} md={2}>
          <Typography className={classes.variableValueText} component="span">
            Valeur : {response?.variableValue}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Item;
