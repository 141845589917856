import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { AdminTestContext } from '@orientaction/Context';
import CircledElement from '@orientaction/commons/CircledElement';
import { publicIconPath } from '@orientaction/utils';
import { FC, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import style from './style';

interface IProps {
  response: any;
  index: number;
}

const ResponseItem: FC<IProps> = ({ response, index }) => {
  const { test, updateTest } = useContext<any>(AdminTestContext);
  const classes = style();

  const handleDelete = () => {
    const toDeleteResponse = test?.questionToCreate?.responses[index];
    const { isNew } = toDeleteResponse;
    const toDeleteResponsesIds = isNew
      ? test?.toDeleteResponsesIds
      : [...test?.toDeleteResponsesIds, toDeleteResponse?.id];
    updateTest({
      questionToCreate: {
        ...test.questionToCreate,
        responses: (test?.questionToCreate?.responses || []).filter(
          (resp: any) => resp.id !== test?.questionToCreate?.responses[index]?.id,
        ),
      },
      toDeleteResponsesIds,
    });
  };

  const handleEdit = () => {
    updateTest({ responseToEdit: response, responseDrawerOpen: true });
  };

  console.log('responseItem', response);
  console.log('responseItemxx', test);
  // fetch variable name from variable object to always get the latest update
  const variableName = (test?.variables || [])
    .filter((v: any) => {
      // S'assurer que response?.variable est traité comme un tableau, même s'il s'agit d'un seul objet ou est undefined/null
      const responseVariables = response?.variable;
      const variablesArray = Array.isArray(responseVariables)
        ? responseVariables
        : responseVariables
        ? [responseVariables]
        : [];

      return variablesArray.some((rv: any) => rv?.id === v?.id);
    })
    .map((v: any) => v?.name)
    .join(', ');

  return (
    <Card className={classes.cardContainer} variant="outlined">
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} sm={2} md={2}>
              <CircledElement>
                <span className={classes.number}>N°{index + 1}</span>
              </CircledElement>
            </Grid>
            <Grid item={true} sm={8} md={8}>
              <Typography className={classes.responseTitle}>
                {ReactHtmlParser(response.title)}
              </Typography>
            </Grid>
            <Grid className={classes.iconContainer} item={true} md={2}>
              <IconButton className={classes.iconButton} onClick={handleEdit}>
                <img src={`${publicIconPath}/oa_edit.svg`} alt="Edit Icon" />
              </IconButton>
              <IconButton className={classes.iconButton} onClick={handleDelete}>
                <img src={`${publicIconPath}/oa_trash.svg`} alt="Custom Icon" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.itemContainer} item={true} xs={12}>
          <Typography className={classes.responseVariableLabel} component="span">
            Variables liées :
          </Typography>
          <Typography className={classes.responseVariableName} component="p">
            {variableName}
          </Typography>
        </Grid>
        <Grid className={classes.itemContainer} item={true} xs={12}>
          <Typography className={classes.responseVariableLabel} component="span">
            Valeur: {response?.variableValue}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ResponseItem;
