/* eslint-disable no-use-before-define */
import { Grid, Slider, Typography, Hidden } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import { dataPreference } from '@orientaction/utils';

export default function SliderScore(props: any) {
  const { pref } = props;
  const classes = useStyles({ color: dataPreference[pref.category].color });

  return (
    <div className={classes.prefCard}>
      <Typography variant="body2" color="primary" className={classes.textHeader}>
        Vous êtes <b>{pref.youAre}</b>
      </Typography>
      <Grid container={true}>
        <Hidden only={['xs', 'sm']}>
          <Grid item={true} xs={1} sm={1} md={1} className={classes.flexCenter}>
            <Typography className={classes.boldMedium} variant="body2" style={{ color: '#242424' }}>
              <b>{pref.score[0].pourcentage}%</b>
            </Typography>
          </Grid>
        </Hidden>
        <Grid item={true} xs={12} sm={12} md={10} style={{ position: 'relative' }}>
          <div className={classes.hiddeDiv} />
          <div className={classes.widthSlider}>
            <Slider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              defaultValue={
                !pref.score[0].isMax ? pref.score[1].pourcentage : pref.score[0].pourcentage
              }
              classes={{
                root: classes.rootSlider,
                thumb: classes.thumb,
                track: classes.track,
                rail: classes.rail,
              }}
              style={{ transform: !pref.score[0].isMax ? 'rotateY(-190deg)' : 'rotateY(0deg)' }}
            />
          </div>
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid
            item={true}
            md={1}
            xs={1} sm={1}
            className={classes.flexCenter}
            style={{ justifyContent: 'flex-end' }}>
            <Typography className={classes.boldMedium} variant="body2" style={{ color: '#242424' }}>
              <b>{pref.score[1].pourcentage}%</b>
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
      <Grid container={true} justifyContent="space-between">
        <Grid item={true} md="auto" className={classes.flexCenter}>
          <Typography
            className={classes.boldMedium}
            variant="body2"
            style={{ color: '#242424', textTransform: 'uppercase' }}>
            <Hidden only={['md', 'lg', 'xl']}>
              <b>{pref.score[0].pourcentage}%</b> <br />
            </Hidden>
            <b>{pref.score[0].label}</b>
          </Typography>
        </Grid>
        <Grid item={true} md="auto" className={classes.flexCenter}>
          <Typography
            className={classes.boldMedium}
            variant="body2"
            style={{ color: '#242424', textTransform: 'uppercase', textAlign: 'right' }}>
            <Hidden only={['md', 'lg', 'xl']}>
              <b>{pref.score[1].pourcentage}%</b> <br />
            </Hidden>
            <b>{pref.score[1].label}</b>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
