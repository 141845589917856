import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { OaPopin, SelectHieachy } from '@orientaction/commons';
import { useCompany } from '@orientaction/hooks';
import { getFromLS, valueAliasRole } from '@orientaction/utils';
import { FC } from 'react';
import { FORM_MODE, generatePasswordOptionalText } from '../constant';
import RandomPasswordSection from '../RandomPasswordSection';
import ServicesSection from './ServicesSection';
import style from './style';
import useManagerForm from './useManagerForm';

interface IProps {
  mode: string;
  alias: any;
  companyUserRoleId: number;
}

const ManagerForm: FC<IProps> = ({ mode, alias, companyUserRoleId }) => {
  const { companyUserRole } = useCompany();
  const {
    loading,
    errors,
    user,
    handleSubmit,
    handleChange,
    handleRoleChange,
    closeDrawer,
    canUpdateHierarchy,
    servicesLoading,
    popin,
    handlePopinConfirm,
    handlePopinClose,
    tabs,
    isRandomPasswordCopied,
    generateRandomPassword,
    copyRandomPassword,
  } = useManagerForm(mode, companyUserRoleId);

  if (!user) {
    return null;
  }

  const classes = style();

  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');

  const btn = () => {
    if (loading) {
      return 'Chargement ... ';
    }

    if (mode === FORM_MODE.update) {
      return 'Enregistrer';
    }

    return 'Créer le compte';
  };
  const isConsultant = companyUserRoleId === 4;
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={classes.marginTop}>
          <Typography className={classes.label}>Rôle</Typography>
          <FormControl
            variant="outlined"
            style={{ width: '100%' }}
            error={!!errors.company_user_role}
          >
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="company_user_role"
              value={user.company_user_role || ''}
              onChange={handleRoleChange}
              placeholder="Entreprise"
              readOnly={!!(mode === 'View')}
              disabled={!!user?.hasMultipleRole}
              className={classes.formControlSelect}
            >
              {companyUserRole
                .map((item: any) => {
                  return {
                    ...item.attributes,
                    id: item.id,
                  };
                })
                .slice(userConnected?.company_user_role?.id || 0)
                .map((item: any) => (
                  <MenuItem value={item.id}>
                    {valueAliasRole(item.name, alias?.attributes?.companyUserRoleAlias)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        {tabs !== 0 && user.company_user_role !== 1 && (
          <div className={classes.marginTop}>
            <SelectHieachy
              value={user.upper_hierarchy}
              alias={alias}
              errorForm={errors}
              readOnly={mode === FORM_MODE.readonly || canUpdateHierarchy}
              title={`Administrateur de rattachement ${
                userConnected.role.type === 'admin' ? '' : '(facultatif)'
              }`}
              name="upper_hierarchy"
              handleChange={handleChange}
              company_user_role={user.company_user_role}
              company={user.company}
              equal={false}
              direction={user.Direction}
            />
          </div>
        )}
        <div className={classes.marginTop}>
          <Typography className={classes.label}>Prénom </Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            className={classes.formInput}
            onChange={handleChange}
            name="firstname"
            value={user.firstname || ''}
            InputProps={{
              readOnly: !!(mode === FORM_MODE.readonly),
            }}
            error={!!errors.firstname}
          />
        </div>
        <div className={classes.marginTop}>
          <Typography className={classes.label}>Nom </Typography>

          <TextField
            id="outlined-basic"
            variant="outlined"
            className={classes.formInput}
            onChange={handleChange}
            name="lastname"
            value={user.lastname || ''}
            error={!!errors.lastname}
            InputProps={{
              readOnly: !!(mode === FORM_MODE.readonly),
            }}
          />
        </div>
        <div className={classes.marginTop}>
          <Typography className={classes.label}>Email </Typography>

          <TextField
            id="outlined-basic"
            variant="outlined"
            className={classes.formInput}
            onChange={handleChange}
            error={!!errors.email}
            InputProps={{
              readOnly: !!(mode === FORM_MODE.readonly),
            }}
            name="email"
            value={user.email || ''}
            helperText={errors.email ? 'Mauvais format' : ''}
          />
        </div>
        <div className={classes.marginTop}>
          <Typography className={classes.label}>
            Mot de passe
            <span
              style={{
                fontWeight: 'lighter',
                fontSize: 12,
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontStyle: 'italic',
              }}
            >
              ({generatePasswordOptionalText})
            </span>
          </Typography>
          <RandomPasswordSection
            password={user.generatedPassword}
            isCopied={isRandomPasswordCopied}
            generatePassword={generateRandomPassword}
            copyPassword={copyRandomPassword}
          />
        </div>
        {isConsultant && (
          <ServicesSection
            loading={servicesLoading}
            handleChange={handleChange}
            services={user.services}
          />
        )}
        <div className={classes.marginTop}>
          <Grid container={true} direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item={true}>
              <Button className={classes.btnWhite} onClick={closeDrawer} disabled={loading}>
                <span className={classes.textHeader}>
                  {mode === FORM_MODE.readonly ? 'Retour' : 'Annuler'}
                </span>
              </Button>

              {mode !== FORM_MODE.readonly && (
                <Button
                  type="submit"
                  className={classes.btnPrimary}
                  disabled={loading}
                  data-cy="submit-create-edit-admin-benef"
                >
                  <span className={classes.textHeader}>{btn()}</span>
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </form>
      <OaPopin
        open={popin.open}
        title={popin.title}
        handleConfirm={handlePopinConfirm}
        handleClose={handlePopinClose}
      >
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalText}>
            {popin.content}
          </Typography>
        </div>
      </OaPopin>
    </>
  );
};

export default ManagerForm;
