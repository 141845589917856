/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Layout } from '@orientaction/components';
import { useStyles } from './style';
import TestMineur from './TestMineur';
import TestMajor from './TestMajor';
import Grid from '@material-ui/core/Grid';
import { debounce } from 'lodash';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useAlert, useCategoryTest, useLanguage, useMajorTest } from '@orientaction/hooks';
import { useEffect, useRef, useState } from 'react';
import { CompassUserProvider } from '@orientaction/Context';

const UserTest = () => {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [minorTestLoading, setMinorTestLoading] = useState<boolean>(false);

  const { list, setList, getFilterTest } = useCategoryTest();

  const alert = useAlert();

  const classes = useStyles();

  const { getMyMajorTest, loading: majorTestLoading, listMajorTest } = useMajorTest();
  const { language } = useLanguage();

  useEffect(() => {
    setList([]);
    getMyMajorTest('');
  }, []);

  const fetchData = async (filter: any) => {
    setMinorTestLoading(true);

    try {
      const response = await getFilterTest(filter);
      setList(response);
    } catch (err) {
      alert.openSimpleAlert();
    } finally {
      setMinorTestLoading(false);
    }
  };

  const debouncedSearch = useRef(
    debounce(async (criteria) => {
      fetchData(criteria);
      getMyMajorTest(criteria);
    }, 1000),
  ).current;

  const handleFilterChange = (e: any) => {
    e.preventDefault();
    if (e.key === 13) {
      fetchData(searchFilter);
      getMyMajorTest(searchFilter);
      return;
    }
    setSearchFilter(e.target.value);
  };

  const search = () => {
    debouncedSearch(searchFilter);
  };

  const handleKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      return debouncedSearch(searchFilter);
    }
    return true;
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    debouncedSearch(searchFilter);
  }, [searchFilter]);

  return (
    <CompassUserProvider>
      <Layout isaddBackground={true} isNew={true}>
        <div className={classes.root}>
          <Grid container={true} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item={true} xs={12} md={3} />
            <Grid item={true} xs={12} md={3}>
              <Paper component="form" className={classes.searchPaper}>
                <InputBase
                  className={classes.searchInput}
                  placeholder="Rechercher un test"
                  value={searchFilter}
                  onChange={handleFilterChange}
                  onKeyDown={handleKeyUp}
                />
                <IconButton
                  onClick={search}
                  className={classes.searchIconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
          <TestMajor
            language={language}
            searchKey={searchFilter}
            majorTests={listMajorTest}
            loading={majorTestLoading}
          />
          <TestMineur minorTests={list} loading={minorTestLoading} />
        </div>
      </Layout>
    </CompassUserProvider>
  );
};

export default UserTest;
