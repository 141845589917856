import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
  },
  icon: {
    width: 30,
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 22,
    },
  },
  text: {
    color: '#000A8C',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    marginLeft: 20,
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 15,
    },
  },
  containerGrey: {
    background: '#F1F1F1',
    borderRadius: 5,
    height: '100%',
    border: '1px solid #E4E6FF',
    padding: '45px 28px 45px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '25px 28px 30px 20px',
      height: 'auto',
    },
  },
}));
