/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line simple-import-sort/imports
import Page01 from './Pages/01';
import Page02 from './Pages/02';
import Page03 from './Pages/03';
import Page04 from './Pages/04';
import Page05 from './Pages/05';
import Page06 from './Pages/06';
import Page07 from './Pages/07';
import Page08 from './Pages/08';
import Page09 from './Pages/09';
import Page10 from './Pages/10';
import Page11 from './Pages/11';
import Page12 from './Pages/12';
import Page13 from './Pages/13';
import Page14 from './Pages/14';
import Page15 from './Pages/15';
import Page16 from './Pages/16';
import Page17 from './Pages/17';
import Page18 from './Pages/18';
import Page19 from './Pages/19';
import Page20 from './Pages/20';
import Page21 from './Pages/21';
import Page22 from './Pages/22';
import Page23 from './Pages/23';
import Page24 from './Pages/24';
import Page25 from './Pages/25';
import Page26 from './Pages/26';
import Page27 from './Pages/27';
import Page28 from './Pages/28';
import Page29 from './Pages/29';
import Page30 from './Pages/30';
import Page31 from './Pages/31';
import Page012 from './Pages/012';
import Page00 from './Pages/00';
import { ButtonSomaire, FooterEbook, PageCouverture } from '@orientaction/components';
import { useTheme } from '@material-ui/core/styles';
import { LayoutResultat } from '@orientaction/layouts';
import useStyles from './style';
import { useBookIntro } from '@orientaction/hooks_book';
import { useEffect, useState } from 'react';
import { useAlert, useRedirectToRootPage } from '@orientaction/hooks';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { useLocation, useParams } from 'react-router-dom';
import { isAdminVerify } from '@orientaction/utils';

const Intro = (props: WithWidth) => {
  const theme = useTheme();

  const alert = useAlert();

  const location = useLocation();

  const { goToRootPage } = useRedirectToRootPage();

  const [readOnly, setReadonly] = useState(true);
  const [loading, setLoading] = useState(true);

  const [users_permissions_user, setUsers_permissions_user] = useState(0);

  const { id }: any = useParams();

  const classe = useStyles();

  const { width } = props;

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const { initValue, getMyValueBook, setId } = useBookIntro();

  const getValue = async (_id: number) => {
    setId(_id);

    setLoading(true);

    try {
      const response = await getMyValueBook(id);

      initValue(response.data.value);
      setReadonly(response.data.readOnly);
      setUsers_permissions_user(response.data.value.users_permissions_user.id);
    } catch (err: any) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getValue(+id);
    setId(+id);
  }, [id]);

  return (
    <>
      <LayoutResultat
        isWhite={true}
        showLogo={false}
        toBack={
          isAdminVerify()
            ? `/admin/ressources-beneficiaire/${users_permissions_user}`
            : '/mes-ressources'
        }>
        {loading ? (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
            }}>
            <p />
            Chargement en cours...
          </div>
        ) : (
          <div className={classe.readOnly}>
            {/* <PageCouverture src="couverture0.jpg" /> */}
            <Page00 />
            <Page01 />
            <Page012 />
            <Page02 />
            <Page03 />
            <Page04 />
            <Page05 />
            <Page06 />
            <Page07 />
            <Page08 />
            <Page09 />
            <Page10 />
            <Page11 />
            <Page12 readOnly={readOnly} />
            <Page13 />
            <Page14 />
            <Page15 />
            <Page16 />
            <Page17 />
            <Page18 />
            <Page19 />
            <Page20 />
            <Page21 />
            <Page22 />
            <Page23 />
            <Page24 />
            <Page25 />
            <Page26 />
            <Page27 />
            <Page28 />
            <Page29 />
            <Page30 />
            <Page31 />
            <div className={classe.marginFooter}>
              <FooterEbook
                ebookName="l’Introduction"
                toBack={
                  isAdminVerify()
                    ? `/admin/ressources-beneficiaire/${users_permissions_user}`
                    : '/mes-ressources'
                }
              />
            </div>
            <ButtonSomaire href="page-5" />
          </div>
        )}
      </LayoutResultat>
    </>
  );
};

export default withWidth()(Intro);
