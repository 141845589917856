/* eslint-disable react/button-has-type */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { CircularProgress, Theme, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import { MarginContainer } from '@orientaction/commons';
import { CardRessource, Layout } from '@orientaction/components';
import { useAlert, useEbook, useLanguage } from '@orientaction/hooks';
import { appLanguage } from '@orientaction/reducers';
import { coverBook, getFromLS } from '@orientaction/utils';
import dateFormat from 'dateformat';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { apiServiceNative } from '../../../utils/fetchNative';
import { getEbookEnRoute } from '../utils';
import './ressourceMobile.css';
import useStyles from './style';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { findIndex, round } from 'lodash';
import { CompassUserContext } from '@orientaction/Context';

SwiperCore.use([Pagination, Navigation]);
const rattachedBenificiaryServiceIds = [1, 5];
const Rattacher = (props: any) => {
  const classes = useStyles();

  const history = useHistory();

  const { language } = useLanguage();

  const alert = useAlert();
  const [activeTab, setActiveTab] = useState('ebooks');

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };
  const [allBook, setAllBook] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState('');

  const [page, setPage] = useState(0);

  const [list, setList] = useState<any>([]);

  const { getMyBookRequest, books } = useEbook({ mode: 'none', user: 'none' });

  const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const [swiper1, setSwiper1] = useState<any>();
  const [swiper2, setSwiper2] = useState<any>();
  const lang: string = useSelector(appLanguage);
  const currentUser = JSON.parse(getFromLS('userInfos') || '{}');
  const { userInfos, loading: loading1 } = useContext<any>(CompassUserContext);
  const myBook = async (text: string, currentPage: number) => {
    setLoading(true);

    setAllBook([]);

    try {
      const reponse = await getMyBookRequest(text, currentPage);

      if (reponse?.data?.list.length > 0) {
        localStorage.setItem('book', reponse?.data?.list[0]?.id);
      }
      let bookMaped: any;

      if (isXS) {
        bookMaped = reponse?.data?.list
          .sort((a: any, b: any) => a.attributes.id - b.attributes.id)
          .map((bookTemplate: any) => {
            return [
              {
                value: bookTemplate.attributes.name,
              },
              {
                value: 'Ebook',
              },
              {
                value: (
                  <>
                    <Link
                      to={
                        lang !== 'FR'
                          ? `/en/${getEbookEnRoute(bookTemplate.attributes.route)}/${
                              bookTemplate?.id
                            }`
                          : `${bookTemplate?.attributes?.route}/${bookTemplate.id}`
                      }>
                      <Button variant="outlined" onClick={updateDateConsultationBtn(bookTemplate)}>
                        Consulter
                      </Button>
                    </Link>
                  </>
                ),
              },
              {
                value: `${dateFormat(
                  bookTemplate.attributes?.DateAttribuate,
                  'dd/mm/yyyy, HH:MM'
                )}`,
              },
              {
                value: bookTemplate.attributes?.dateConsultation ? 'Lu' : 'Non lu',
              },
              {
                value: `${
                  bookTemplate.attributes?.dateConsultation
                    ? dateFormat(
                        new Date(bookTemplate.attributes?.dateConsultation),
                        'dd/mm/yyyy, HH:MM'
                      )
                    : 'Pas encore consulté'
                }`,
              },
            ];
          });
      } else {
        bookMaped = reponse?.data?.list
          .sort((a: any, b: any) => a.attributes.id - b.attributes.id)
          .map((bookTemplate: any) => {
            return [
              {
                value: bookTemplate.attributes.name,
              },
              {
                value: 'Ebook',
              },
              {
                value: `${dateFormat(
                  bookTemplate.attributes?.DateAttribuate,
                  'dd/mm/yyyy, HH:MM'
                )}`,
              },
              {
                value: bookTemplate.attributes?.dateConsultation ? 'Lu' : 'Non lu',
              },
              {
                value: (
                  <Link
                    to={
                      lang !== 'FR'
                        ? `/en/${getEbookEnRoute(bookTemplate.attributes.route)}/${
                            bookTemplate?.id
                          }`
                        : `${bookTemplate?.attributes?.route}/${bookTemplate.id}`
                    }>
                    <Button variant="outlined" onClick={updateDateConsultationBtn(bookTemplate)}>
                      Consulter
                    </Button>
                  </Link>
                ),
              },
              {
                value: `${
                  bookTemplate.attributes?.dateConsultation
                    ? dateFormat(
                        new Date(bookTemplate.attributes?.dateConsultation),
                        'dd/mm/yyyy, HH:MM'
                      )
                    : 'Pas encore consulté'
                }`,
              },
            ];
          });
      }
      console.log({ bookMaped });
      setAllBook(reponse?.data?.list.sort((a: any, b: any) => a.attributes.id - b.attributes.id));
      // setAllBook([]);
    } catch (error) {
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // const delayDebounceFn = setTimeout(() => {
    myBook(filter, page);
    //  return () => clearTimeout(delayDebounceFn)
  }, [isXS, filter, page, lang]);

  const handleSearch = (e: any) => {
    setFilter(e.target.value);
  };

  const updateDateConsultationBtn = (data: any) => (e: any) => {
    const path =
      language !== 'FR'
        ? `${getEbookEnRoute(data.attributes.route)}/${data.id}`
        : `${data.attributes.route}/${data.id}`;
    console.log('PATH ', path);
    console.log('history', history);
    //  history.push(lang !== 'FR' ? `en/${path}` : path);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const getListGame = async () => {
    setLoading(true);
    const response = await apiServiceNative.get('/api/game-list-user');
    console.log({ response });
    setList(response);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getListGame();
      // console.log({ response });
    })();
  }, []);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (isXS) {
      const data = sessionStorage.getItem('redirect');
      const parseData = JSON.parse(data as string);
      console.log({ parseData });
      if (parseData) {
        setActiveTab(parseData[0]);
        if (swiper1 && list) {
          const index = findIndex(list, { idGame: parseInt(parseData[1], 10) });
          console.log({ index, list });
          swiper1.slideTo(index);
          setTimeout(() => sessionStorage.removeItem('redirect'), 1000);
        }
      }
    }
  }, [isXS, swiper1, list]);

  const initSwiper1 = (swiper: any) => {
    setSwiper1(swiper);
  };
  const initSwiper2 = (swiper: any) => {
    setSwiper2(swiper);
  };
  return (
    <Layout isaddBackground={true} noPadding={true} isNew3={true} footer={true}>
      {(allBook && allBook.length > 0) || (list && list.length > 0) ? (
        <>
          {!isMobile ? (
            <div className={classes.paddingY}>
              <Grid
                container={true}
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Grid item={true} xs={12} md={3}>
                  <Typography variant="h2" className={classes.colorWhite}>
                    Mes ressources{' '}
                  </Typography>
                </Grid>
              </Grid>
              <div className={classes.separator} />
              {rattachedBenificiaryServiceIds.includes(userInfos?.service?.id) &&
                userInfos?.rattachement &&
                allBook &&
                allBook.length > 0 && (
                  <div>
                    <MarginContainer>
                      <Typography className={classes.sousTitre}>Mes ebooks</Typography>
                    </MarginContainer>

                    <Grid container={true} spacing={5}>
                      {allBook?.map((book: any, index: number) => (
                        <Grid item={true} sm="auto">
                          <CardRessource
                            linkTo={
                              lang !== 'FR'
                                ? `/en/${getEbookEnRoute(book.attributes.route)}/${book?.id}`
                                : `${book?.attributes?.route}/${book.id}`
                            }
                            title={book.attributes.name}
                            readingDate={
                              book.attributes?.dateConsultation
                                ? dateFormat(
                                    book.attributes?.dateConsultation,
                                    'dd/mm/yyyy - HH:MM'
                                  )
                                : 'Pas encore consulté'
                            }
                            assignationDate={dateFormat(
                              book.attributes?.DateAttribuate,
                              'dd/mm/yyyy - HH:MM'
                            )}
                            elem={
                              <img
                                className={classes.img}
                                src={`${coverBook}/book${index}.jpg`}
                                alt="page covurture"
                              />
                            }
                            updateDate={() => updateDateConsultationBtn(book)}
                            isEbook={true}
                            processReading={book.attributes?.dateConsultation ? 'Lu' : 'Non lu'}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}
              {rattachedBenificiaryServiceIds.includes(userInfos?.service?.id) &&
                userInfos?.rattachement &&
                allBook &&
                allBook.length > 0 &&
                list &&
                list.length > 0 && <div className={classes.separator2} />}
              {list && list.length > 0 && (
                <div>
                  <MarginContainer>
                    <Typography className={classes.sousTitre}>Mes serious games</Typography>
                  </MarginContainer>

                  <Grid container={true} spacing={5}>
                    {list?.map((book: any, index: number) => (
                      <Grid item={true} sm="auto">
                        <CardRessource
                          title={book.title}
                          id={book.idGame}
                          elem={
                            <img
                              className={classes.img}
                              src={`${book.couverture}`}
                              alt="page covurture"
                            />
                          }
                          readingDate={
                            !!book.date_consultation
                              ? dateFormat(book.date_consultation, 'dd/mm/yyyy - HH:MM')
                              : null
                          }
                          idResult={book.idResult}
                          idLastPage={book.last_id_page_visited}
                          processReading={`${
                            !book.hasMainPath || !book.date_consultation
                              ? 'nothing'
                              : book.progress === 100 || book.isRestart
                              ? 'Terminé'
                              : `${round(book.progress)}%`
                          }`}
                          isRestart={book.isRestart}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="ressource_mobile">
                <h1>Mes ressources</h1>
                <div className="tabs">
                  <button
                    className={activeTab === 'ebooks' ? 'active' : ''}
                    onClick={() => handleTabChange('ebooks')}>
                    Mes ebooks
                  </button>
                  <button
                    className={activeTab === 'seriousgame' ? 'active' : ''}
                    onClick={() => handleTabChange('seriousgame')}>
                    Mes serious games
                  </button>
                </div>
                <div className="content">
                  {activeTab === 'ebooks' && (
                    <Swiper
                      spaceBetween={50}
                      slidesPerView={1}
                      navigation={true}
                      pagination={{ clickable: true, type: 'bullets' }}>
                      {allBook?.map((book: any, index: number) => (
                        <SwiperSlide key={book.id}>
                          <Grid item={true} sm="auto" style={{ padding: '0px 35px' }}>
                            <CardRessource
                              linkTo={
                                lang !== 'FR'
                                  ? `/en/${getEbookEnRoute(book.attributes.route)}/${book?.id}`
                                  : `${book?.attributes?.route}/${book.id}`
                              }
                              title={book.attributes.name}
                              readingDate={
                                book.attributes?.dateConsultation
                                  ? dateFormat(
                                      book.attributes?.dateConsultation,
                                      'dd/mm/yyyy - HH:MM'
                                    )
                                  : 'Pas encore consulté'
                              }
                              assignationDate={dateFormat(
                                book.attributes?.DateAttribuate,
                                'dd/mm/yyyy - HH:MM'
                              )}
                              elem={
                                <img
                                  className={classes.img}
                                  src={`${coverBook}/book${index}.jpg`}
                                  alt="page covurture"
                                />
                              }
                              updateDate={() => updateDateConsultationBtn(book)}
                              isEbook={true}
                              processReading={book.attributes?.dateConsultation ? 'Lu' : 'Non lu'}
                              isMobile={true}
                            />
                          </Grid>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                  {activeTab === 'seriousgame' && (
                    <div className="seriousgame-content">
                      <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={true}
                        onSwiper={initSwiper1}
                        pagination={{ clickable: true, type: 'bullets' }}>
                        {list?.map((book: any, index: number) => (
                          <SwiperSlide key={book.id}>
                            <Grid item={true} sm="auto" style={{ padding: '0px 35px' }}>
                              <CardRessource
                                title={book.title}
                                id={book.idGame}
                                elem={
                                  <img
                                    className={classes.img}
                                    src={`${book.couverture}`}
                                    alt="page covurture"
                                  />
                                }
                                readingDate={
                                  !!book.date_consultation
                                    ? dateFormat(book.date_consultation, 'dd/mm/yyyy - HH:MM')
                                    : null
                                }
                                idResult={book.idResult}
                                idLastPage={book.last_id_page_visited}
                                processReading={`${
                                  !book.hasMainPath || !book.date_consultation
                                    ? 'nothing'
                                    : book.progress === 100
                                    ? 'Terminé'
                                    : `${round(book.progress)}%`
                                }`}
                                isRestart={book.isRestart}
                                isMobile={true}
                              />
                            </Grid>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ height: '75vh' }}>
          {(loading || loading1) && (
            <CircularProgress color="inherit" size={30} style={{ color: 'white' }} />
          )}
          {!(loading || loading1) && (
            <Grid item={true} xs={12} md={3}>
              <Typography
                variant="h2"
                className={classes.colorWhite}
                style={{ textAlign: 'center' }}>
                Aucun élément pour le moment
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Layout>
  );
};

export default withWidth()(Rattacher);
